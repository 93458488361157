@import '../../styles/variables';
.AddressBook-Container {
  // box-shadow: 0px 1px 6px #00000012;
  // padding: 20px 24px;
  .MuiAppBar-colorPrimary {
    background-color: white !important;
    z-index: unset !important;
  }
  .My-Address-Book-Tab {
    margin-top: 9px !important;
    // margin-left: 20px;
  }
  .Favourites-Tab {
    margin-top: 7px !important;
    // margin-left: 42px;
  }
  .MuiPaper-elevation4 {
    box-shadow: unset !important;
    padding-bottom: 15px;
  }
  .MuiTab-root.MuiTab-textColorInherit {
    span {
      color: #000000e6 !important;
      //font-weight: bold !important;
      font-size: 16px !important;
      letter-spacing: 0 !important;
      text-transform: none !important;
    }
  }
  .MuiTab-root.MuiTab-textColorInherit.Mui-selected {
    span {
      color: #000000e6 !important;
      font-weight: bold !important;
      font-size: 16px !important;
      letter-spacing: 0 !important;
    }
  }
  .MuiTabs-indicator {
    border: 2px solid #d40511;
    height: 0;
    // width: 20% !important;
  }
  overflow: hidden;
  .DynamicMaterialTable-main-div {
    .MuiPaper-elevation2 {
      box-shadow: none;
    }
  }
  .DynamicMaterialTable-main-div > div > div {
    width: 1200px;
  }
  .Favourites-Tab {
    .DynamicMaterialTable-main-div > div > div {
      width: 1200px;
    }
  }
}
.MuiFormControl-fullWidth {
  width: 100% !important;
}
.AddressBook-AppBar {
  margin-left: 0px;
  margin-top: 0px;
  // width: 98% !important;
}
.AddressBook-Search-control {
  flex: 0.9 1;
  .Addessbook-Searchbar {
    float: right;
    width: 89%;
    .makeStyles-SearchAddressField-50 {
      padding-right: 3px;
    }
  }
}
.AddressBook-Delete {
  button {
    padding: 11px 43px;
  }
}
.Export-Btn.MuiGrid-item {
  padding: 8px 15px !important;
  a {
    border: 1px solid #d40511;
    display: block;
    height: 48px;
    padding: 5px 43px;
    text-decoration: none;
    border-radius: 4px;
    color: #d40511;
    font: 16px/32px $font-bold;
  }
}
.MuiButton-root.Delete-btn {
  padding: 11px 33px;
  height: 48px;
  font-family: $font-bold;
  text-transform: none;
}
.My-address-book-Tab-panel {
  .MuiTabs-indicator {
    width: 118px !important;
  }
}
.Favourites-Tab-panel {
  .MuiTabs-indicator {
    left: 160px !important;
    width: 72px !important;
  }
}
.is_Drawer_Open.makeStyles-content-1 {
  overflow-x: hidden;
}
.is_Drawer_Open {
  .My-Address-Book-Tab {
    .DynamicMaterialTable-main-div > div > div {
      width: 984px;
    }
  }
  .Favourites-Tab {
    .DynamicMaterialTable-main-div > div > div {
      width: 985px;
    }
  }
  .shipment {
    #addDomesticConsignment {
      width: 99%;
    }
  }
}
@media screen and (min-width: 1920px) and (max-width: 2560px) {
  .makeStyles-content-1 {
    min-height: 1080px;
  }
  .AddressBook-Container {
    .Favourites-Tab {
      .DynamicMaterialTable-main-div > div > div {
        width: 100%;
      }
    }
    .DynamicMaterialTable-main-div > div > div {
      width: 100%;
    }
  }

  .is_Drawer_Open {
    .My-Address-Book-Tab {
      .DynamicMaterialTable-main-div > div > div {
        width: 100%;
      }
    }
    .Favourites-Tab {
      .DynamicMaterialTable-main-div > div > div {
        width: 100%;
      }
    }
  }
}
@media screen and (min-width: 3072px) and (max-width: 4560px) {
  .My-Address-Book-Tab {
    .DynamicMaterialTable-main-div > div > div {
      width: 100%;
    }
  }
  .Favourites-Tab {
    .DynamicMaterialTable-main-div > div > div {
      width: 100%;
    }
  }
  .is_Drawer_Open {
    .My-Address-Book-Tab {
      .DynamicMaterialTable-main-div > div > div {
        width: 100%;
      }
    }
    .Favourites-Tab {
      .DynamicMaterialTable-main-div > div > div {
        width: 100%;
      }
    }
  }
}
