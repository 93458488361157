@import '../../styles/variables';
.config-title {
  font: 14px/16px $font-bold;
  margin-bottom: 25px;
}
.config-items {
  .MuiGrid-item {
    .MuiCard-root {
      border: 1px solid #d0d0d0;
      box-shadow: 0px 1px 6px #d0d0d0 !important;
      .MuiCardContent-root {
        p {
          padding-top: 25px;
          font: 14px/19px $font-regular;
        }
      }
    }
  }
}
