.App-header {
    background-color: #282c34;
    min-height: 5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    margin-bottom: 5vh;
    text-align: center;
  }

  .wrapper-class {
    padding: 5px;
    // border: 1px solid #ccc;
  }
  .editor-class {
    background-color:lightgray;
    padding: 1rem;
    border: 1px solid #ccc;
  }
  .toolbar-class {
    border: 1px solid #ccc;
  }

  .App{
    .MuiFormControl-root{
      width: 100%;
      text-align: left;
    border: 1px solid #ccc !important;
    border-radius:5px;
      .labelText{
      
      margin: 7px 11px;
   
      font-size: 13px;
      color: rgba(0, 0, 0, 0.73);
      padding: 0;
   
      font-family: Delivery Regular;
      font-weight: 400;
      line-height: 1;
  
        span{
          color: red;
        }
      }
    }
    .editor-class{
      background-color: #fff;
      border: none;
    }
    .errorText{
      margin: 0;
      font-size: 0.75rem;
      margin-top: 3px;
      text-align: left;
      font-family: Delivery Regular;
      font-weight: 400;
      line-height: 1.66;
      margin-right: 14px;
      color: red !important;
    margin-left: 0 !important;
    float: left;
    }
  }


