@import '../styles/variables';
@import '../styles/mixin.scss';

strong,
b {
  font-family: $font-bold;
}

input[type='number'] {
  font-size: 16px;
  background-image: url('data:image/svg+xml;utf8,<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 39.94"><g id="Group_7942" data-name="Group 7942"><g id="arrow"><path id="color" d="M20,30.6l4.85-4.85.8.8L20,32.19l-5.65-5.64.8-.8Z"/></g><g id="arrow-2"><path id="color-2" d="M20,13.34l-4.85,4.85-.8-.8L20,11.75l5.65,5.64-.8.8Z"/></g></g></svg>');
  background-position: right;
  background-repeat: no-repeat;
}

.MuiPaper-elevation1.MuiPaper-root {
  box-shadow: none !important;
}

#length,
#width,
#height {
  background-image: none !important;
}

input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  opacity: 1 !important;
  height: 31px;
  width: 23px;
  cursor: pointer;
  margin-bottom: 10px;
}

.MuiBadge-colorError {
  background: #ea7601 !important;
  width: auto !important;
  height: 21px !important;
  padding-right: 7px !important;
  padding-bottom: 2px !important;
}

.makeStyles-content-1 {
  padding: 0px 0px 13.6px 18.6px !important;
}

.MuiInputBase-input.Mui-disabled {
  color: black !important;
  font-size: 16px !important;
  background-image: unset !important;
}

.basis-fonts {
  font-size: 14px !important;
  color: #000000e6 !important;
  opacity: 1;
  text-align: left !important;
  letter-spacing: 0 !important;
}

.MuiFormLabel-root {
  top: -6px !important;
  color: #000000e6 !important;
}

.Filter_List_Outlined_Icon {
  @include position(relative, $top: 4px);
}

.MuiTypography-body1 {
  @extend .basis-fonts;
}

.span-required {
  color: red;
  font-size: 0.75rem;
}

.span-normal {
  font-size: 0.75rem;
}

.MuiAccordionSummary-content {
  @extend .basis-fonts;
  font-family: $font-bold;
}

.MuiAccordion-rounded {
  border: 1px solid #e3e3e3;
}

.MuiAccordionSummary-root.Mui-expanded {
  min-height: 34px !important;
  height: 34px !important;
}

.product-Accordion {
  .Mui-expanded {
    .MuiCollapse-container {
      height: 109px !important;
      min-height: 0px !important;
    }
  }
}

.More-informations-Accordion {
  .Mui-expanded {
    .MuiCollapse-container {
      height: 187px !important;
      min-height: 0px !important;
    }
  }
}

.MuiAccordionSummary-content.Mui-expanded {
  margin: unset;
  font: 14px $font-bold;
}

.menu-icon,
.menu-icon2 {
  height: 50px !important;
}

.parent-menu-icon-btn {
  margin-left: 5px !important;
}

.parent-menu-icon-btn,
.parent-menu-icon-btn2 {
  padding: unset !important;
}

.parent-menu-icon-btn2 {
  padding: inherit !important;
}

.parent-menu-icon-btn:hover {
  background-color: transparent !important;
  text-decoration: none !important;
}

.Breadcrumbs-Dashboard-btn {
  height: 40px;
}

.Go-btn {
  padding: 13px 32px !important;
  text-align: center !important;
}

.MuiFilledInput-input {
  padding: 18px 11px 5px !important;
}

.side-bar-icon {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 2.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
  margin-left: -4px;
}

.Parcel-Search-Icon-btn {
  padding-right: 0px !important;
  padding: unset !important;
}

.MuiAccordion-root.Mui-expanded {
  border: 1px solid #e3e3e3 !important;
  box-shadow: 0px 1px 6px #00000012 !important;
}

.MuiAccordion-root {
  margin: 14px -1px 1px 0 !important;
}

.Favourites-mani-grid {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

#mui-component-select-accountNumber {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.AccountNumber-main-grid {
  padding-top: 20px !important;
}
.AutoComplete_inputRoot_parent_class{
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 0px;
  }
}

.makeStyles-divider-24 {
  width: 1px !important;
}

.unset-padding-top-bottom {
  padding-top: unset !important;
  padding-bottom: unset !important;
}

.searchFor-Main {
  .MuiFormLabel-root {
    top: -8px !important;
  }

  .MuiButtonBase-root {
    margin-top: -16px !important;
  }

  .Mui-focused {
    margin-top: 11px !important;
    color: rgba(0, 0, 0, 0.87) !important;

    .MuiInput-input.MuiInputBase-inputAdornedEnd {
      margin-top: 4px !important;
    }

    .MuiButtonBase-root {
      margin-top: -10px !important;
    }
  }
}

.searchFor-Main-Padding {
  .MuiFormLabel-root {
    top: 2px !important;
    transform: translate(0, 1.5px) scale(0.75);
    transform-origin: top left;
  }

  .MuiButtonBase-root {
    margin-top: -13px !important;
  }

  .MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedEnd {
    margin-top: -5px !important;
  }

  .Mui-focused {
    top: -9px !important;

    .MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedEnd {
      margin-top: 9px !important;
    }

    .MuiButtonBase-root {
      margin-top: 1px !important;
    }
  }
}

.main-searchBy label:first-child {
  top: -6px !important;
}

.Setup-details-searchBy {
  .main-searchBy {
    flex: 0.6 1;
  }
}

.contact-main-grid div:nth-child(1) {
  padding-left: 4px !important;
}

.contact-main-grid img {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 2.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  -webkit-user-select: none;
  user-select: none;
}

.Addshipmentrouter {
  font-size: 20px !important;
  margin-top: -5px !important;
  color: rgba(0, 0, 0, 0.8) !important;
}

.Table-column-color {
  tbody tr:nth-child(odd) {
    background-color: #ededed;
  }

  tbody {
    font-size: 14px !important;

    tr {
      td {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        margin: 0 !important;
        font-size: 14px !important;
      }
    }
  }
}

.drawerClosed {

  .MuiListItemText-root,
  .arrow-icon {
    display: none;
  }
}

.drawerClose {
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;

  &:hover {
    overflow-y: auto;
  }

  .dashboardSideNav span {
    font-size: 16px !important;
  }

  .sidenavShipment span {
    color: #d40511 !important;
    font-size: 16px !important;
  }

  .addsidenavShipment span {
    font-family: $font-bold !important;
  }

  .menuIcons {
    svg {
      .cls-1 {
        stroke-width: 0.2px !important;
        fill: #050605 !important;
      }

      .cls-2 {
        stroke-width: 0.2px !important;
        fill: #050605 !important;
      }
    }
  }

  .main-title {
    color: #d40511 !important;
    font-family: $font-regular !important;

    span {
      color: #d40511 !important;
      font-family: $font-regular !important;
    }

    // svg {
    //   .cls-1 {
    //     fill: #d40511 !important;
    //     stroke-width: 0.2px !important;
    //   }
    //   .cls-2 {
    //     stroke-width: 0.2px !important;
    //   }
    // }
    .menuIcons {
      svg {
        .cls-1 {
          stroke-width: 0.2px !important;
          fill: #d40511 !important;
          stroke: #d40511 !important;
        }

        .cls-2 {
          stroke-width: 0.2px !important;
          fill: #d40511 !important;
          stroke: #d40511 !important;
        }

        path {
          fill: #d40511 !important;
          stroke: #d40511 !important;
          stroke-width: 0.2px !important;
        }

        text {
          fill: #d40511 !important;
        }
      }
    }
  }

  .audit-icon.menuIcons {
    svg {
      left: 7px;
      top: 10px;
    }
  }

  .user-icon.menuIcons {
    svg {
      left: 6px;
      top: 7px;
    }
  }

  .menuIcons.shipment-icon {
    svg {
      left: -13px;
      top: -6px;
    }
  }

  .MuiCollapse-wrapper {
    margin-left: -10px !important;
  }

  .MuiCollapse-wrapperInner {
    margin-left: 15% !important;
  }

  .MuiList-root {
    .MuiCollapse-container.MuiCollapse-entered {
      margin-left: unset !important;
      background-color: #0000000d !important;
      width: auto;
    }
  }

  .MuiListItemIcon-root {
    min-width: auto;

    .MuiButtonBase-root {
      padding: 0;
    }
  }

  .menuIcons {
    svg {
      font-size: 37px;
      @include position(relative, $top: -4px, $left: -10px);
    }
  }

  .MuiTypography-body1 {
    font-size: 16px !important;
  }

  .shipmentSubmenu {
    .MuiTypography-body1 {
      font-size: 14px !important;
    }

    .MuiListItem-root {
      padding-left: 25px !important;
    }
  }
}

.MuiBreadcrumbs-li {
  margin-top: -3px !important;
}

.shipmentrouter {
  margin-top: -3px !important;
  opacity: 0.8 !important;
  text-decoration: none !important;
  font-size: 12px !important;
  color: #000000e6 !important;
}

.dialogTitle {
  font: 16px/18px $font-bold;
  padding-top: 3% !important;
}

.DateRangePicker__WeekdayHeading {
  border-bottom: none;

  abbr[title] {
    font-family: $font-regular;
    color: #000000;
    opacity: 0.5;
  }
}

.DateRangePicker__CalendarHighlight--single {
  border: 1px solid #ffc107;
}

.DateRangePicker__Date {
  color: #000000;
  font: 16px/18px $font-regular;
  border: none;
}

.DateRangePicker__CalendarSelection--is-pending {
  background: #ffc107;
}

.DateRangePicker__CalendarSelection {
  background-color: #ffc107;
  border: 1px solid #ffc107;
}

.dialogPopData {
  font-size: 16px !important;
  color: #000000e6 !important;
  letter-spacing: 0 !important;
}

.commonlabel-font {
  color: #707070 !important;
  font-size: 16px !important;
}

.labelTextfield {
  @extend .commonlabel-font;
}

.labelSelect {
  @extend .commonlabel-font;
}

.Customernumber {
  @extend .commonlabel-font;
}

.MTablePaginationInner-root-24 {
  margin-left: 800px !important;
}

.My-Address-Book-Tab {
  table {

    thead,
    tbody {
      opacity: 1;
    }
  }
}

.MuiMenuItem-root {
  font-size: 14px !important;
}

.MuiBackdrop-root {
  background-color: #ffcc00f2;
  opacity: 0.95;
}

.AddressBook-AddFav {
  margin-right: -34px !important;
}

.common-button-style {
  background: #ffffff99 0% 0% no-repeat padding-box !important;
  border: 1px solid #d40511 !important;
  border-color: #d40511 !important;
  color: #d40511 !important;
  font-size: 16px !important;
  text-transform: none !important;
}

.Add-Account-to-all-users,
.Add-to-Account,
.Clone-user .Delete-AddressBook,
.Add-fav-btn,
.Favourites-Remove {
  @extend .common-button-style;
}

.Re-print-btn {
  background: #d40511 0% 0% no-repeat padding-box !important;
  border: 2px solid #d40511 !important;
  border-color: #d40511 !important;
  color: white !important;
  font-size: 16px !important;
}

.AddressBook-RemoveFav {
  width: 96px !important;
}

.Favourites-Remove {
  margin-left: 108px !important;
}

.AddressBook-Search-control {
  .Addessbook-Searchbar>div>div {
    margin-top: -22px;
    margin-left: 37px;
  }
}

.is_Drawer_Open {
  .AddressBook-Search-control {
    .Addessbook-Searchbar>div>div {
      margin-top: -22px;
      margin-left: 8px;
    }
  }
}

.Favourites-Search-control {
  .Addessbook-Searchbar>div>div {
    margin-top: -22px;
    margin-left: 0px;
  }
}

.AddressBook-main-container {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 6px #00000012;
  border: 1px solid #f2f2f2;
  opacity: 1;
}

.AddressBook-Child-container {
  width: 640px;
  /* Can be in percentage also. */
  height: auto;
  margin: 0 auto;
  padding: 10px;
  position: relative;
}

.MyTemplate-Tab .DynamicMaterialTable-main-div tfoot tr td div div:nth-child(5) {
  margin-left: 540px !important;
}

.is_Drawer_Open {
  .MyTemplate-Tab .DynamicMaterialTable-main-div tfoot tr td div div:nth-child(5) {
    margin-left: 430px !important;
  }
}

.DynamicMaterialTable-main-div>div>div {
  border: 1px solid #a7a0a0;
  background: #ffffff99 0% 0% no-repeat padding-box !important;
}

.Addessbook-Searchbar {
  width: 95%;
}

.MuiTablePagination-caption {
  padding-left: 3%;
  display: unset !important;
  color: #4b4b4b;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

.form input[type='file'] {
  z-index: 999;
  line-height: 0;
  font-size: 50px;
  position: absolute;
  opacity: 0;
  filter: alpha(opacity=0);
  -ms-filter: 'alpha(opacity=0)';
  cursor: pointer;
  _cursor: hand;
  margin: 0;
  padding: 0;
  left: 0;
}

.add-photo-btn {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  text-align: center;
  border: 1px solid #d40511;
  box-shadow: 0px 0px 0px #d40511;
  border-radius: 4px;
  color: #d40511;
  display: block;
  width: 130px;
  height: 36px;
  font-size: 16px;
  line-height: 30px;
  float: left;
}

input[type='text'] {
  float: left;
}

.MuiFormHelperText-root {
  color: red !important;
  margin-left: 0 !important;
}

.monitor-button {
  height: 32px;
  @include rotate(270);
  cursor: pointer;
}

#monitor-list-btn {
  @extend .monitor-button;
  z-index: 9;
  @include rotate(270);
  width: 524px;
  background: #f2f2f2 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #bababa;
  border-radius: 3px;
  border-bottom-color: white;
}

.Monitor-list-icon {
  @include position(absolute, $top: 448px, $right: -242px);
}

.Monitor-list-icon.alertDialogContainer {
  @include position(absolute, $top: 507px, $right: -242px);
}

#monitor-list-btn:focus {
  outline: unset !important;
}

#Drawer-monitor-list-btn {
  width: 500px;
  @extend .monitor-button;
  @include position(absolute, $top: 232px, $left: -235px);
  border: none;

  .collapse-icon {
    margin: 5px 5px 0 0;
  }
}

.eye-icon-btn {
  padding-right: 10px;
  font: 16px/22px $font-regular;
}

#monitor-list-btn span,
.eye-icon {
  @extend .eye-icon-btn;
}

#Drawer-monitor-list-btn span,
.eye-icon {
  @extend .eye-icon-btn;
}

.Dashboard-monitor-list {
  padding: 10px 16px 0 40px;
  transition: 0.8s ease-in;
  min-height: 395px;
}

.Dashboard-monitor-list {
  .MuiPaper-elevation2 {
    position: unset !important;
  }
}

.Filter_List_Outlined_Icon {
  transform: rotate(7deg);
  -ms-transform: rotate(180deg);
  /* IE 9 */
  -moz-transform: rotate(180deg);
  /* Firefox */
  -webkit-transform: rotate(180deg);
  /* Safari and Chrome */
  -o-transform: rotate(180deg);
  /* Opera */
}

.monitor-list-drawer {
  .MuiDrawer-paper {
    top: 70px;
    width: 910px !important;
    height: 500px !important;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000004d;
    border: 1px solid #e6e6e6;
    opacity: 1;
  }
}

.accountsTabContaner {
  .MuiTabs-indicator {
    background-color: #d40511 !important;
    width: 90px !important;
    margin-left: 35px !important;
    border: 2px solid #d40511 !important;
    height: 0 !important;
  }
}

.print-btn {
  width: unset !important;
  float: right;
}

.Dashboard-monitor-list>div>div {
  border: 1px solid #a7a0a0;
  background: #ffffff99 0% 0% no-repeat padding-box !important;
}

button:disabled {
  cursor: not-allowed !important;
  pointer-events: all !important;
  opacity: 0.3 !important;
}

.doughnut-with-panel {
  .legend-container {
    // padding-left: 57%;
    // margin-top: -50%;
    // position: inherit;
  }
}

.doughnut-without-panel {
  .legend-container {
    // padding-left: 57%;
    // margin-top: -45%;
    // position: inherit;
  }
}

.doughnut-with-panel,
.doughnut-without-panel {
  .legend-container {
    position: absolute;
    top: 0;
    left: 50%;
  }
}

.MuiTablePagination-selectRoot {
  border-top: 1px solid #acacac;
  border-right: 1px solid #acacac;
  border-bottom: 1px solid #acacac;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  height: 44px;
}

p[id^='mui-'] {
  padding: 5px;
  padding-top: 10px;
  border-top: 1px solid #acacac;
  border-left: 1px solid #acacac;
  border-bottom: 1px solid #acacac;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  height: 44px;
}

.drawerClosed {
  .detailsview {
    padding-right: 12% !important;
    position: absolute !important;
    padding-top: 61% !important;
  }

  .detailsview-Without-Panel {
    padding-right: 8% !important;
    position: absolute !important;
    padding-top: 61% !important;
  }
}

.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded {
  max-height: 50% !important;
}

.MuiInputLabel-formControl {
  top: -5 !important;
}

.MuiTypography-root.MuiFormControlLabel-label.Mui-disabled.MuiTypography-body1 {
  opacity: 0.3 !important;
}

.MuiButtonBase-root.MuiIconButton-root.PrivateSwitchBase-root-21.MuiRadio-root.makeStyles-root-26.PrivateSwitchBase-disabled-23.Mui-disabled.Mui-disabled.Mui-disabled {
  .MuiIconButton-label {
    opacity: 0.3 !important;
  }
}

.MuiInputBase-root.Mui-disabled {
  opacity: 0.7 !important;
}

.addToFav {
  .MuiTypography-root.MuiFormControlLabel-label.Mui-disabled.MuiTypography-body1 {
    opacity: 1 !important;
  }
}

.AccountNumber-main-grid {
  .labelSelect {
    font-size: 12px !important;
  }

  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, 0px) scale(0.75);
  }
}

.MuiPickersToolbar-toolbar {
  background-color: #ffcc00 !important;
}

.MuiPickersDay-daySelected {
  background-color: #ffcc00 !important;
}

.MuiPickersToolbarText-toolbarTxt {
  color: white !important;
}

.MuiPickersModal-dialogRoot {
  .MuiDialogActions-root {
    button:nth-child(1) {
      color: red;
      font-weight: $font-bold;
    }

    button:nth-child(2) {
      color: white;
      background-color: red;
      font-weight: $font-bold;
    }
  }
}

.delivery-Address {
  .main-searchBy {
    flex: 0.5 1;
  }
}

.is_Drawer_Open {
  .delivery-Address {
    .main-searchBy {
      flex: 1;
    }
  }

  .Setup-details-searchBy .main-searchBy {
    flex: 1;
  }
}

.Dispatch_date .MuiIconButton-root {
  padding: 6px !important;
}

.Dispatch_date #date-picker-dialog {
  padding-right: 0px !important;
}

.Add_shipment_tableContainer {
  box-shadow: unset !important;
  border-radius: unset !important;
}

.search_Icon {
  padding: 10px !important;
}

.commodityDetails-popup {
  .dialogContent {
    height: 450px !important;
  }
}

.internationalProduct-togglebutton {
  .MuiToggleButtonGroup-root {
    .MuiButtonBase-root:nth-child(1) {
      width: 50%;
      margin-right: 73px;
    }

    .MuiButtonBase-root:nth-child(2) {
      width: 50%;
    }
  }
}

.MuiInputBase-input.Mui-disabled:hover {
  background: rgba(0, 0, 0, 0.12) !important;
}

.delivery_Address_right_child_grid {
  width: 124% !important;
  margin-left: 0px !important;
}

.delivery_Address_right_child_grid_two {
  width: 111% !important;
  margin-left: 0px !important;
}

.is_Drawer_Open {
  .delivery_Address_right_child_grid {
    width: 127% !important;
  }

  .delivery_Address_right_child_grid_two {
    width: 113% !important;
  }
}

.parcel-details-edit-btn {
  margin-left: 17px;
  margin-top: 1% !important;

  svg {
    .cls-1 {
      fill: none;
    }
  }
}

.is_Drawer_Open {
  .parcel-details-edit-btn {
    margin-left: unset !important;
    margin-top: 1% !important;
    @include position(relative, $top: 2px, $right: -10px);
  }
}

.Tab-panel {
  float: left !important;
  display: block !important;
  padding-left: unset !important;
  padding-bottom: 2px !important;

  .MuiTab-wrapper {
    align-items: baseline !important;
  }
}

.My_templates {
  padding-left: 35px !important;
}

.MuiTableCell-head {
  color: black !important;
}

.template-Searchbar {
  float: right;

  .Template-search {
    .search-icon-temp {
      padding-right: 0 !important;
    }
  }
}

.template-Searchbar>div>div {
  margin-top: -37px;
  margin-left: 21px;
}

.is_Drawer_Open {
  .template-Searchbar>div>div {
    margin-left: 17px;
  }
}

.template-Searchbar {
  width: 95%;
}

.MuiTablePagination-toolbar {
  height: 54px !important;
}

.pagination-select-icon {
  top: calc(50% - 12px);
  color: rgba(0, 0, 0, 0.54);
  right: 10px;
  position: absolute;
  pointer-events: none;
}

.MuiTablePagination-select {
  padding-left: 10px !important;
}

// scrollBar css
.table-wrapper {
  height: 400px;
  max-height: 400px;
  overflow-y: auto;
  text-align: left;
  width: fit-content;
  border-radius: 30px;
}

.table-wrapper * {
  margin: 0;
  box-sizing: border-box;
}

.table-wrapper table {
  border-collapse: separate;
  font-size: 13px;
  background-color: #121212;
  text-transform: uppercase;
  border-spacing: 0;
  table-layout: fixed;
  color: #fff;
}

table thead tr th {
  padding: 18px 24px;
  position: sticky;
  top: 0;
}

table tbody tr td {
  padding: 11px 24px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.DynamicMaterialTable-main-div {
  ::-webkit-scrollbar {
    width: 8px;
  }
}

// End
.AddressBook-Search-control {
  .main-searchBy {
    flex: 0.9 1;
  }
}

.MuiDialog-root.Template-DialogContainer.template-dialog {
  // width: 400px;
  // height: 380px;
  // margin-left: 780px;
  // margin-top: 100px;
}

// .MuiDialog-root.Template-DialogContainer.favourite-dialog {
//   width: 435px !important;
//   height: 380px !important;
//   margin-left: 915px !important;
//   margin-top: 100px !important;
.MuiDialog-paper {
  min-width: 80%;
  overflow-x: hidden !important;
}
.export-pop .MuiDialogContent-root{
  padding-left: 36px !important;
  padding-bottom: 3px !important;
}
.export-pop .MuiDialog-paper{
  min-width: 50%;
}

//}
.is_Drawer_Open {
  /* overflow-x: scroll;*/
}

.DynamicMaterialTable-main-div {
  .MuiTableCell-root {
    line-height: 1.2;
    border: none;
  }

  th {
    font: 16px/18px $font-bold;
  }

  .MuiTablePagination-toolbar {
    margin-top: 9px;
    margin-left: -10px;
    margin-right: 0;
  }
}

.isClearExistingAddress {
  padding-top: 15px;
}

.isClearExistingAddress,
.ignoreFirstRow,
.overwriteDuplicates {
  .MuiButtonBase-root {
    padding-top: 0px;
    padding-bottom: 10px;
  }

  .MuiFormControlLabel-label {
    padding-bottom: 10px;
  }
}

.popupMappingDltBtn {
  padding: unset !important;
}

.shipment-Management-child-table,
.shipmentDetailView-Table {
  table:first-child {
    display: block !important;
    width: 1000px;
  }

  .DynamicMaterialTable-main-div .MuiTable-root .MuiTableFooter-root .MuiToolbar-root .MuiSelect-root {
    padding-right: 48px;
  }
}

.bookedCollection-child-Tab-panel {
  // padding-left: 25px !important;
}

.shipment_box {
  width: 99%;
  border-radius: 5px;
  padding: 18px !important;
  margin: unset !important;

  b {
    font-family: $font-bold;
  }
}

.Top-10px {
  padding-top: 10px;
}

.FilterIcon {
  padding: 2px 0 0 4px;
}

.MuiDialogTitle-root {
  padding: 16px 34px !important;
}

.MuiTab-root {
  .MuiTabs-indicator {
    display: none;
  }
}

.MuiFormLabel-root.Mui-focused {
  color: inherit !important;
}

.MuiFormLabel-root.Mui-focused {
  color: #000000e6 !important;
}

.template-select label {
  color: #000000 !important;
}

.create-user-popup {
  width: 97%;
  overflow: hidden;
  word-break: break-all;
  display: inline-block;
}

.radio-label {
  .MuiFormLabel-root {
    font-size: 14px;
  }
}

.DateRangePicker__Date--is-disabled {
  color: #cdcdd1 !important;
  cursor: default;
}

.doughnut-chart-number {
  margin: 100px auto 0;
  text-align: center;
  color: #717171;
  font: 16px/18px $font-bold !important;
}

.transitionAlert .MuiAlert-action {
  svg {
    fill: #d40511;
    width: 21px;
    height: 21px;
  }
}

.application-Table {
  .MuiPaper-elevation2 {
    box-shadow: none;
  }

  .Top-10px {
    .text-btn {
      border: 1px solid #d40511;
      padding: 5px 30px;
    }
  }
}

.preview-btn-container.MuiGrid-spacing-xs-3 {
  margin-top: 25px;
  width: calc(100% + 18px);
}

.MuiMenu-list {
  .MuiCheckbox-colorSecondary {
    .MuiIconButton-label {
      .MuiSvgIcon-root {
        border: 1px solid grey;
        font-size: 1.2rem;
        width: 24px;
        height: 24px;
        border-radius: 4px;
        fill: none;
      }
    }
  }

  .Mui-checked {
    .MuiIconButton-label {
      .MuiSvgIcon-root {
        fill: #626262;
      }
    }
  }
}

.previewDeliveryOption {
  overflow-wrap: break-word;
}

.dashBoard-account-selectBox {
  word-break: normal;
  white-space: inherit !important;
}

.sideListItem-custom-width {
  span {
    width: 190px;
  }
}

.TrackingFindShipmentItem {
  position: absolute;
  top: -40px;
  right: 0;
}

.OnStopItem {
  background-color: #dedada !important;
}

.OnStopDropdown {

  .MuiFilledInput-root,
  .MuiSelect-select {
    background-color: #f5f5f5 !important;
  }
}

#track-list-btn {
  @extend .monitor-button;
  z-index: 9;
  @include rotate(0);
  height: 35px !important;
  width: 30px;
  background: #ffe26f 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #bababa;
  border-radius: 4px 0px 0px 4px;
  border-right-color: white;
}

.Track-list-icon {
  @include position(absolute, $top: 142px, $right: 0px);
}

#Drawer-track-list-btn {
  width: 610px;
  @extend .monitor-button;
  @include position(absolute, $top: 232px, $left: -290px);
  border: none;

  .collapse-icon {
    margin: 5px 5px 0 0;
  }
}

.track-list-drawer {
  .MuiDrawer-paper {
    top: 70px;
    width: 1236px !important;
    height: 530px !important;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #0000004d;
    border: 1px solid #e6e6e6;
    opacity: 1;
    border-radius: 7px 0px 0px 7px;
  }
}

.orderNotesDrawer {
  background: #ffe26f 79%;
}

.orderNotesTable {
  padding: 30px;

  .DynamicMaterialTable-main-div>div>div {
    border-radius: 5px;
    height: 400px;
  }
}

.OrderNotesHeader {
  padding: 30px 0px 0px 55px;
  font: 18px/24px Delivery_W_Bd;
}

.OrderNotesCloseBtn {
  width: 35px;
  height: 35px;
  opacity: 10;
  cursor: pointer;
  @include position(absolute, $top: 30px, $right: 30px);
}

.OrderNotesIcon {
  @include position(absolute, $top: 30px, $left: 30px);
}

.MuiTooltip-popper {
  pointer-events: unset !important;
}

.MuiTypography-root {
  word-wrap: break-word;
}

.AccountsDropDownRoot {
  .MuiAutocomplete-root {
    .MuiFormControl-root {
      .Mui-focused:nth-child(2) {
        height: auto;
        max-height: 150px;
        overflow-y: scroll;
        position: unset;
      }
    }
  }
}

.AccountsDropDownRoot {
  .MuiAutocomplete-root {
    .MuiFormControl-root {
      .MuiInputBase-root {
        height: fit-content;
      }
    }
  }
}

.newRetail-submit-btn {
  position: absolute;
  bottom: 0px;
}

.amendInfoTable {
  height: 600px !important;

  .MuiDialog-paper {
    // width: 30% !important;
    min-height: 400px !important;
  }

  padding: 10px 30px 10px 30px !important;

  .DynamicMaterialTable-main-div>div>div>div>div {
    border-radius: 5px;
    height: 320px;
  }
}

.infoOkButton {
  float: right;
  padding-top: 10px;
}

.shipmentInfoTable {
  padding-bottom: 5px;
  padding-left: 10px;
  font-size: 15px;
}

.AddShipment-DimTable{
  .MuiTableBody-root{
    .MuiTableRow-root{
      vertical-align: top !important;
    }
  }
}

.MuiAutocomplete-popper {
  background-color: #fff;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 4px;
}

.export-pop {
  .MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthMd.MuiDialog-paperFullWidth{
    height: 200px !important;
  }
}