@import './../../styles/mixin.scss';
@import '../../../src/styles/variables';
.custom-accordion {
  @include accordian-header;
  // @include accordion-Content;
  .heading {
    font: 14px/18px $font-bold;
  }
  .content {
    padding: 15px 25px 15px;
  }
  border: 1px solid #e3e3e3 !important;
  box-shadow: 0px 1px 6px #00000012 !important;
  .checkBoxHeading {
    padding: 10px;
    p {
      margin: 0;
    }
  }
}
