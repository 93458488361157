.date-container {
  .textfield-disabled {
    .MuiFilledInput-root.Mui-disabled {
      background-color: white !important;
      opacity: 1 !important;
    }
  }
}

.futuredate-container .textfield-disabled .MuiFilledInput-root.Mui-disabled {
  background-color:#FFEFAE !important;
}