.shipment_box {
  .box-accrodion {
    padding-bottom: 15px;
    margin-bottom: 15px;
    &:last-child {
      padding: 0;
      margin: 0;
    }
  }
}
.shipment-btn-continer.MuiGrid-spacing-xs-3 {
  padding-top: 20px;
  width: 99%;
  margin: 0;
  .MuiGrid-item {
    padding: 0;
  }
  .outlined-btn {
    padding: 5px 22px;
    margin: 0 21px;
  }
  .contained-btn {
    padding: 5px 25px;
    margin-right: 5px;
  }
}
