.multiple-select-container {
  .multiple-select {
    display: inline-flex !important;
    height: 44px !important;
    width: 100% !important;
    border: 1px solid #00000053 !important;
    border-radius: 4px !important;
    .labelSelect {
      margin-left: 6px !important;
      margin-top: 8px !important;
    }
  }
  .MuiFormLabel-root.labelSelect {
    margin-top: 12px !important;
  }
  .MuiInputBase-root.MuiInput-root.MuiInputBase-formControl.MuiInput-formControl {
    margin-top: 9px !important;
  }
  .MuiFormLabel-root.labelSelect.MuiFormLabel-filled {
    margin-top: 6px !important;
  }

  .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input {
    margin-top: -18px !important;
    margin-left: 10px !important;
  }
  .MuiSelect-select:focus {
    background-color: transparent !important;
  }

  .MuiSvgIcon-root.MuiSelect-icon {
    margin-top: -20px !important;
    margin-right: 10px !important;
  }
}
.main-checkbox {
  .MuiCheckbox-root {
    color: #fff;
    transition: none;
  }
  .MuiCheckbox-colorSecondary {
    width: 5%;
    height: 3px;
    padding: 5px;
    margin: 13px;
    left: 0;
    right: 0;
  }
  .MuiCheckbox-colorSecondary.Mui-checked {
    // color: #fff;
    background-color: unset;
    svg {
      fill: #626262;
    }
  }
  .MuiCheckbox-colorSecondary.Mui-checked:hover {
    background-color: unset;
  }
  .MuiCheckbox-colorSecondary.Mui-disabled {
    color: #fff;
    width: 5%;
    height: 3px;
    padding: 5px;
    margin: 13px;
    left: 0px;
    right: 7px;

    .MuiIconButton-label {
      .MuiSvgIcon-root {
        // border: 2px solid rgba(0, 0, 0, 0.26);
        // border-radius: 2px;
        // font-size: 1.2rem;
      }
    }
  }
  .MuiIconButton-label {
    .MuiSvgIcon-root {
      //   border: 2px solid gray;
      //   border-radius: 2px;
      //   font-size: 1.2rem;
      border: 1px solid grey;
      font-size: 1.2rem;
      width: 24px;
      height: 24px;
      border-radius: 4px;
      fill: none;
    }
  }
}
