@import '../../../../styles/mixin.scss';
@import '../../../../styles/variables';

@include width-100();

.columnHeading {
  font-weight: bold;
  font-size: 14px;
}
.volumetricWt {
  color: #707070 !important;
  font-size: 14px !important;
}
.Select-service-dialog {
  .MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthMd.MuiDialog-paperFullWidth {
    // border: 2px solid green !important;
    width: 746px !important;
    max-width: 746px;
    min-width: auto;
    top: 0 !important;
    height: 550px !important;
  }
  .dialogTitle.MuiGrid-item {
    font: 20px/24px $font-bold;
    padding-left: 0;
  }
}
.volumetict-wt-container {
  margin-top: 8px;
}
.main-Add-Parcel.MuiGrid-item {
  padding-right: 0 !important;
}
.dimTableBorder{
  border: 1px solid lightgrey;
  border-radius: 4px;
}
