@import '../../../../styles/variables';
@import '../../../../styles/mixin.scss';
.pb-0 {
  padding-bottom: 0 !important;
}
.dashboardContainer {
  .MuiToggleButtonGroup-root {
    height: 40px;
  }
  .accountsTabContaner {
    padding-top: 0;
    .chartSection {
      height: 460px;
      width: 100%;
      .YesDayPanel,
      .toDayPanel {
        width: 100%;
        .doughnut-with-panel {
          // position: relative;
          width: 100%;
        }
      }
      .MuiCollapse-container {
        .MuiAccordionDetails-root {
          height: 400px;
        }
      }
    }
    .MuiBox-root {
      padding: 0;
    }
    .doughnut-container {
      canvas {
        height: 220px !important;
        width: 450px !important;
        margin-left: -15%;
        margin-top: 40px;
        cursor: pointer;
      }
      height: 307px;
      .legend-container {
        .list-chart {
          width: 100%;
          position: relative;
          top: 60px;
          margin: 0;
          li {
            width: 100%;
            margin-bottom: 5px;
            .chart-items {
              display: flex;
              .chart-item-list {
                width: 77px;
              }
            }
            .chart-legend-label-text {
              padding-right: 15px;
              font: 14px/40px $font-light;
              display: block;
              float: left;
            }
            .pie-charrt-span {
              display: block;
              float: left;
              height: 30px !important;
            }
            .labels-legend {
              position: relative;
              top: -4px;
              float: left;
              width: 100%;
              font-size: 12px;
            }
            .pie-StatusCount-span {
              font-family: $font-bold;
              padding-left: 0;
              font-size: 16px;
              position: relative;
              top: -7px;
            }
          }
        }
      }
    }
    .detailsview-Without-Panel {
      @include position(absolute, $bottom: 15px, $right: 15px);
      padding: 0;
      .MuiGrid-item {
        margin: 0 !important;
      }
      .MuiButtonBase-root {
        padding: 0;
        &:hover {
          background: none;
        }
      }
    }
  }
  .MuiButton-endIcon {
    margin-top: 10px;
  }
  .SelectContainer {
    .MuiGrid-item {
      padding: 26px 12px 12px;
    }
    .multiple-select-container {
      .multiple-select {
        label {
          font-size: 12px !important;
          margin: 5px 6px !important;
        }
      }
      .MuiSvgIcon-root.MuiSelect-icon {
        margin-top: -17px !important;
      }
    }
  }

  .BreakdownContaner {
    .accordion-Container {
      width: 100%;
      margin-top: 7px;
      .MuiAccordion-root.custom-accordion {
        margin: 0 !important;
      }
    }
    .breakdown-container {
      position: relative;
      height: 383px;
    }
    .barMain_Head {
      .chartjs-render-monitor {
        width: 96% !important;
        margin-left: 1%;
        height: 275px !important;
        cursor: pointer;
      }
    }
    .BreakDBtn {
      padding: 0;
      @include position(absolute, $bottom: 10px, $right: 0);
      a {
        &:hover {
          background: none;
        }
      }
    }
  }
  .domestic-chart {
    .breakdown-container {
      height: 396px;
    }
  }
  .international-chart {
    .accordion-Container {
      margin-top: 10px;
    }
    .breakdown-container {
      height: 397px;
    }
  }
  .searchContainer {
    margin: 0 10px !important;
    border-radius: 5px;
    height: 136px;
    .searchText {
      font: 18px/24px $font-bold;
      padding-bottom: 0;
    }
    .searchControl {
      padding: 28px ​12px 28px;
    }
  }
  .MuiToggleButtonGroup-groupedHorizontal {
    opacity: 0.5 !important;
    border: 1px solid #00000073 !important;
  }
  .MuiToggleButton-root.Mui-selected {
    opacity: 1 !important;
    border: 1px solid #000000;
  }
  .MuiToggleButton-label {
    color: #000 !important;
    text-transform: none !important;
    font-size: 16px;
  }
  .MuiButtonBase-root.MuiIconButton-root.MuiAccordionSummary-expandIcon.Mui-expanded.MuiIconButton-edgeEnd {
    visibility: hidden !important;
  }
  .MuiTabs-scroller.MuiTabs-fixed {
    .MuiTabs-indicator span {
      background-color: #d40511 !important;
    }
  }
  .MuiTab-wrapper {
    font-size: 16px !important;
    text-transform: none !important;
  }
  .detailsview {
    @include position(absolute, $bottom: 10px, $right: 5px);
    a {
      &:hover {
        background: none;
      }
    }
  }
}
.datePicker-dialog-box {
  width: 413px !important;
  margin: auto !important;
  .MuiFilledInput-root {
    background-color: unset !important;
  }
  .MuiInputLabel-filled.MuiInputLabel-shrink {
    color: #707070 !important;
  }
  .DateRangePicker {
    border: 1px solid #c6c6c6 !important;
    width: 300px !important;
    top: 20px !important;
    .DateRangePicker__MonthHeader {
      background: #e8e8e8 0% 0% no-repeat padding-box !important;
      width: 298px !important;
      left: -20px !important;
    }
  }
  .MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthMd.MuiDialog-paperFullWidth {
    height: 560px !important;
    top: auto !important;
  }
  .datepicker-btn-grid {
    padding-left: 28%;
    .datepicker-btn {
      width: 50px !important;
      top: 35px !important;
      left: 50% !important;
    }
  }
}
.custom-accordion .content {
  padding: 12px 16px 15px;
  position: relative;
}
#dashboardForm {
  .MuiAccordionDetails-root.content {
    .MuiTabs-flexContainer {
      .MuiTab-root {
        min-width: auto;
        margin: 0 30px;
        opacity: 1;
        border-bottom: 4px solid transparent;
        padding: 0;
        min-height: 30px;
      }
      button:first-child {
        margin: 0;
      }
      button.Mui-selected {
        border-bottom: 4px solid #d40511;
        font-family: $font-bold;
      }
    }
    .MuiTabs-indicator {
      display: none !important;
    }
  }
}
.is_Drawer_Open {
  .dashboardContainer {
    .accountsTabContaner {
      .accordion-Container {
        .custom-accordion {
        }
      }
      .doughnut-container {
        height: 300px;
        canvas {
          margin-left: -18%;
          margin-top: 54px;
        }
        .legend-container {
          .list-chart {
            // top: -32px;
            // padding-left: 30px;
          }
        }
      }
      .doughnut-without-panel {
        .legend-container {
          // padding-left: 55%;
        }
      }
    }
  }
  .domestic-chart {
    .breakdown-container {
      height: 394px;
    }
  }
  .international-chart {
    .breakdown-container {
      height: 396px;
    }
  }
}
@media screen and (min-width: 1800px) {
  .dashboardContainer {
    .accountsTabContaner {
      .doughnut-container {
        canvas {
          margin-left: -6%;
        }
      }
    }
  }
}
@media screen and (min-width: 1200px) and (max-width: 1280px) {
  .dashboardContainer {
    .accountsTabContaner {
      .doughnut-container {
        canvas {
          width: 90% !important;
          height: auto !important;
        }
      }
    }
  }
}
