@import '../../../styles/mixin.scss';
@import '../../../styles/variables.scss';

.tracking {
  @include customCard();
  .banner-item {
    background: transparent linear-gradient(90deg, #ffcc00 0%, #ffcc00 30%, #ffe57f 79%, #fff0b2 100%) 0% 0% no-repeat
      padding-box;
    box-shadow: 0px 3px 6px #00000017;
    height: 111px;
    border-radius: 0px;
  }
}
