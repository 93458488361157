@import '../../../../styles/variables';
.sub-header {
  font-size: 12px;
  color: #2e2e2e;
}
.sub-content {
  font-size: 16px;
  color: #000000;
}
.DialogContainer.shipment-dimention-dialog {
  .MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthMd.MuiDialog-paperFullWidth {
    width: 890px !important;
    top: 0 !important;
  }
  .dialogContent {
    padding: 16px 34px;
    .dialogTitle {
      font-size: 20px;
    }
    .MuiTabs-root {
      button {
        padding: 0 0 7px;
        min-width: auto;
        min-height: inherit;
        text-transform: inherit;
        border-bottom: 4px solid transparent;
        font: 16px/18px $font-regular;
      }
      .Mui-selected {
        font-family: $font-bold;
        border-bottom: 4px solid #d40511;
      }
      .MuiTabs-indicator {
        display: none;
      }
    }
    table {
      thead {
        tr {
          th {
            font: 16px/22px $font-bold;
          }
        }
      }
      tbody {
        tr {
          td {
            font: 16px/22px $font-regular;
          }
        }
      }
    }
    .MuiTableContainer-root {
      box-shadow: none;
    }
  }
}
