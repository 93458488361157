.BookDriverPickUpContainer {
  .MuiFormControl-root {
    width: 100%;
  }
}
#bookDriverPickUpForm {
  width: 99%;
  .MuiBox-root {
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1);
    padding: 25px;
    margin: 0 0 25px;
  }
  textarea {
    padding: 0 !important;
  }
  .cancel-btn {
    padding: 5px 33px;
  }
  .pickup-btn {
    padding: 5px 24px;
  }
  .btn-container {
    margin-top: 60px;
  }
}
