// $mainWidth: 512px;
// $mainHeight: 300px;

// .modalDescription {
//     width: $mainWidth;
//     height: $mainHeight;
// }
.modalAddress {
    display: flex;
    justify-content: space-between;
}
