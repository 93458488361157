@import '../../styles/_variables';
.VerticalLinearStepper {
  .MuiStepLabel-root.MuiStepLabel-alternativeLabel {
    flex-direction: row;
  }

  .MuiStepLabel-labelContainer {
    margin-left: 10px;
  }
  .MuiStepLabel-label {
    color: #000;
    text-align: left !important;
  }
  .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    margin-top: 8px;
  }
  .MuiStepContent-root {
    margin-left: 4px;
    margin-top: 0px;
  }
  .MuiStepContent-root {
    border-left: 1px solid #bdbdbd;
  }
  .MuiStep-root:first-child {
    .MuiStepContent-root {
      border-left: unset;
    }
  }
  .MuiGrid-container {
    padding-bottom: 10px;
  }
}
.delivery-history-dialog {
  .MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthMd.MuiDialog-paperFullWidth {
    width: 955px !important;
    height: 460px !important;
    top: 0 !important;
    .MuiGrid-container {
      margin: 0;
    }
    .dialogTitle {
      font: 20px/24px $font-bold;
      margin-top: 0 !important;
      padding: 21px 0 0 !important;
    }
    .dialogContent {
      padding: 0 34px;
    }
    .TrackingStepperDetails {
      .MuiGrid-container {
        .MuiGrid-item {
          &:first-child {
            padding-left: 0;
          }
        }
      }
    }
  }
  .sub-title {
    font: 16px/18px $font-bold;
  }
}
