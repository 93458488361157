@import '../../../../styles/variables';
.printer-queue-Tab {
  .add-btn-printerq {
    color: #000000e6 !important;
    font: 16px/22px $font-regular;
    position: relative;
    margin-bottom: 15px;
    svg {
      position: absolute;
      left: -20px;
      top: 6px;
      .cls-1 {
        opacity: 1 !important;
      }
    }
  }
  .btn-container {
    margin-top: 20px;
    .contained-btn {
      padding: 5px 18px;
    }
  }
}
.add-remove-printerq-popup {
  .MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthMd.MuiDialog-paperFullWidth {
    min-width: 878px;
    height: 500px !important;
    top: 0 !important;
    .MuiDialogTitle-root {
      .MuiButtonBase-root {
        padding-right: 0;
      }
    }
  }
  .dialogContent {
    padding: 8px 34px;
  }
}
.popup-remove-message {
  .MuiDialog-paper {
    min-width: auto;
  }
  .dialogContent {
    padding: 8px 34px;
  }
}
