#printerConfiguration {
  .custom-accordion {
    .content {
      padding: 15px 16px;
    }
  }
  .btn-container {
    margin-top: 200px;
    .contained-btn {
      padding: 5px 35px;
    }
  }
}
.is_Drawer_Open.makeStyles-content-1 {
  overflow-x: inherit;
}
