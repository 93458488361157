@import '../../../styles/variables';
.shipmentContainer {
  .star.Favourites-mani-grid {
    .arrowDown1 span {
      margin-top: -5px !important;
    }

    .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated {
      font-size: 16px !important;
      margin-top: 4% !important;
    }
    .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiFormLabel-filled {
      margin-top: 10px !important;
      color: #656565 !important;
    }
    .MuiInputBase-input.MuiInput-input {
      color: #000000e6 !important;
    }
  }
  .template-select {
    min-width: 90px;
  }
}
.template-ddl {
  .MuiIconButton-root {
    float: right;
    padding: 15px 0 0;
    svg {
      .cls-1 {
        stroke: none;
      }
    }
  }
}
.temp-label {
  padding-left: 20px;
  font: 16px/18px $font-bold;
}
