@import '../../../styles/variables';
@import '../../../styles/mixin.scss';

.span-text-fromat {
  color: #000000e6 !important;
  font-size: 16px !important;
  letter-spacing: 0 !important;
}
.AddressBook-Container {
  .MuiAppBar-colorPrimary {
    background-color: white !important;
    z-index: unset !important;
  }
  .My-Address-Book-Tab {
    margin-top: 9px !important;
  }
  .Favourites-Tab {
    margin-top: 7px !important;
  }
  .MuiPaper-elevation4 {
    box-shadow: unset !important;
  }
  .MuiTab-root.MuiTab-textColorInherit {
    span {
      @extend .span-text-fromat;
      text-transform: none !important;
    }
    min-width: auto;
    padding-right: 0;
    font-family: $font-regular;
    border-bottom: 4px solid transparent;
    opacity: 1;
    &:last-child {
      margin-left: 38px !important;
    }
  }
  .MuiTab-root.MuiTab-textColorInherit.Mui-selected {
    border-bottom: 4px solid #d40511;
    span {
      &:first-child {
        @extend .span-text-fromat;
        font-family: $font-bold !important;
      }
    }
  }
  .MuiTabs-indicator {
    border: 2px solid #d40511;
    height: 0;
    // width: 20% !important;
  }
}

.AddressBook-AppBar {
  margin-left: 0px;
  margin-top: 0px;
  // width: 98% !important;
}

.Addessbook-Searchbar {
  float: right;
}

.My-address-book-Tab-panel {
  .MuiTabs-indicator {
    width: 118px !important;
  }
}
.Favourites-Tab-panel {
  .MuiTabs-indicator {
    left: 160px !important;
    width: 72px !important;
  }
}
