@import '../../../styles/variables';
.paper-container.MuiPaper-elevation1.MuiPaper-root {
  padding-top: 1%;
  background: #ffffff;
  //box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1) !important;
  //border: 1px solid #f2f2f2;
  opacity: 1;
  .MuiAppBar-colorPrimary {
    background-color: white !important;
    z-index: unset !important;
  }
  .OrderFileConfiguration-container {
    padding: 15px;
    .OrderFileConfiguration-tabs-container {
    }
    .MuiTabs-scrollButtons.Mui-disabled {
      opacity: 0.3;
    }
    .MuiTab-wrapper {
      text-transform: none !important;
    }
    .MuiButtonBase-root.MuiTab-root.MuiTab-textColorInherit {
      font: 16px/18px $font-regular;
      color: #000000e6 !important;
      border-bottom: 4px solid transparent;
      padding: 0 0 5px !important;
      opacity: 1;
      min-width: auto;
      min-height: auto;
      margin-right: 35px;
    }
    .MuiButtonBase-root.MuiTab-root.MuiTab-textColorInherit.Mui-selected {
      border-bottom: 4px solid #d40511;
      font-family: $font-bold;
    }
    .MuiTabs-indicator {
      //   background-color: #d40511 !important;
      //   height: 4px !important;
      display: none;
    }
  }
}

.AddressBook-AppBar {
  margin-left: 0px;
  margin-top: 0px;
  // width: 98% !important;
}

.Addessbook-Searchbar {
  float: right;
}
.order-filemap-Tab {
  margin-top: -20px;
  .service-title {
    font: 14px/18px $font-bold;
  }
  .addedit-btn {
    padding: 0;
    color: #000000e6;
    svg {
      fill: #000000e6;
      path {
        stroke: #000000e6;
        opacity: 1;
      }
    }
  }
  .addTitle {
    font-size: 14px;
    position: relative;
    top: -4px;
  }
  .MuiPaper-elevation2 {
    box-shadow: none;
  }
  .DynamicMaterialTable-main-div > div > div {
    border: 1px solid #b5b5b5;
  }
  .btn-container {
    padding-top: 25px;
    .save {
      padding: 5px 18px;
    }
  }
}
.addedit-dialog {
  .parcelDimensionsHeading {
    font: 20px/24px $font-bold;
  }
  .common-padding {
    padding-left: 8px;
    padding-right: 8px;
  }
  .save {
    padding: 5px 41px;
    margin-top: 30px;
  }
  .add-btn {
    padding: 8px 38px;
    height: 40px;
  }
  table {
    thead {
      tr {
        th {
          font-size: 16px;
        }
      }
    }
  }
}
.orderfile-papercontainer.paper-container.MuiPaper-elevation1.MuiPaper-root {
  border: 1px solid #f2f2f2 !important;
  border-radius: none !important;
  box-shadow: 0px 1px 6px #00000012 !important;
}

.add-marketplace-btn{
  padding: 8px 38px;
  height: 44px;
}