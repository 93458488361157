@mixin transform($transforms) {
  -moz-transform: $transforms;
  -o-transform: $transforms;
  -ms-transform: $transforms;
  -webkit-transform: $transforms;
  transform: $transforms;
}
// rotate
@mixin rotate($deg) {
  @include transform(rotate(#{$deg}deg));
}

// scale
@mixin scale($scale) {
  @include transform(scale($scale));
}
// translate
@mixin translate($x, $y) {
  @include transform(translate($x, $y));
}
// skew
@mixin skew($x, $y) {
  @include transform(skew(#{$x}deg, #{$y}deg));
}
//transform origin
@mixin transform-origin($origin) {
  moz-transform-origin: $origin;
  -o-transform-origin: $origin;
  -ms-transform-origin: $origin;
  -webkit-transform-origin: $origin;
  transform-origin: $origin;
}
//Position
@mixin position($position, $top: null, $right: null, $bottom: null, $left: null) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}
@mixin width-100 {
  .wd-100 {
    width: 100%;
  }
}
@mixin accordian-header {
  .expandedPanel {
    background: #f0f0f0 0% 0% no-repeat padding-box;
  }
}
@mixin customButtons {
  .contained-btn {
    background-color: #d40511 !important;
    letter-spacing: 0 !important;
    min-width: 104px !important;
    width: 100% !important;
    text-align: center !important;
    letter-spacing: 1px !important;
    color: #ffffff !important;
    opacity: 1;
  }
  .contained-btn,
  .outlined-btn,
  .text-btn {
    font: 16px Delivery_W_Bd !important;
    height: 48px;
    text-transform: none !important;
  }
  .outlined-btn {
    border: 1px solid #d40511 !important;
    text-align: center !important;
    color: #d40511 !important;
    opacity: 1 !important;
  }
  .text-btn {
    color: #d40511 !important;
    font-size: 16px !important;
    font-weight: bold !important;
    height: 48px;
    text-transform: none;
  }
  .plain-btn {
    color: #000000 !important;
    text-transform: none !important;
    font-size: 16px !important;
    font-weight: bold !important;
  }
}
.Checkbox-outer {
  border: 1px solid gray;
  font-size: 1.2rem;
  width: 24px;
  height: 24px;
  border-radius: 4px;
  fill: none;
}
/*
.MuiInputBase-input.Mui-disabled {
  color: black !important;
  font-size: 16px !important;
}
.MuiFormLabel-root {
  font-size: 14px !important;
  color: #000000e6 !important;
  opacity: 1;
  text-align: left !important;
  letter-spacing: 0 !important ;
  top: -6px !important;
}
.MuiTypography-body1 {
  font-size: 14px !important;
  text-align: left !important ;
  letter-spacing: 0px !important;
  color: #000000e6 !important;
  opacity: 1 !important;
}

.span-required {
  color: red;
}

.MuiAccordionSummary-content {
  font-size: 14px !important;
  font-weight: bold;
  letter-spacing: 0px;
  color: #000000e6;
  opacity: 1;
}

.MuiAccordion-rounded {
  border: 1px solid #e3e3e3;
}

.MuiAccordionSummary-root.Mui-expanded {
  min-height: 34px !important;
  height: 34px !important;
}
.product-Accordion {
  .Mui-expanded {
    .MuiCollapse-container {
      height: 109px !important;
      min-height: 0px !important;
    }
  }
}
.More-informations-Accordion {
  .Mui-expanded {
    .MuiCollapse-container {
      height: 187px !important;
      min-height: 0px !important;
    }
  }
}
.MuiAccordionSummary-content.Mui-expanded {
  margin: unset !important;
  font-size: 14px !important;
}

.menu-icon {
  height: 50px !important;
}
.menu-icon2 {
  height: 50px !important;
}

.parent-menu-icon-btn {
  margin-left: 5px !important;
  padding: unset !important;
}
.parent-menu-icon-btn2 {
  padding: inherit !important;
}

.parent-menu-icon-btn:hover {
  background-color: transparent !important;
  text-decoration: none !important;
}

.Breadcrumbs-Dashboard-btn {
  height: 40px;
}

.Go-btn {
  padding: 13px 32px !important;
  text-align: center !important;
}
.MuiFilledInput-input {
  padding: 18px 11px 5px !important;
}

.side-bar-icon {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 2.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
  margin-left: -4px;
}

.Parcel-Search-Icon-btn {
  padding-right: 0px !important;
  padding: unset !important;
}

// .parcel-main-grid,
// .productservice-main-grid,
// .additioninfo-main-grid,
// .returnoption-main-grid {
//   padding-top: 10px;
// }

// .returnoption-main-grid {
//   height: 105px !important;
// }
.MuiAccordion-root.Mui-expanded {
  margin: 14px -1px 1px 0px !important;
  border: 1px solid #e3e3e3 !important;
  box-shadow: 0px 1px 6px #00000012 !important;
}

.Favourites-mani-grid {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}

#mui-component-select-accountNumber {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.AccountNumber-main-grid {
  padding-top: 18px !important;
}

.makeStyles-divider-24 {
  width: 1px !important;
}
// .MuiCollapse-container.MuiCollapse-entered{
//   .MuiGrid-root.returnoption-main-grid.MuiGrid-container{
// min-height: 200px !important;
//   }
//  }
.unset-padding-top-bottom {
  padding-top: unset !important;
  padding-bottom: unset !important;
}

.searchFor-Main {
  .MuiFormLabel-root {
    top: -8px !important;
  }
  .MuiButtonBase-root {
    margin-top: -16px !important;
  }
  .Mui-focused {
    margin-top: 11px !important;
    // .MuiButtonBase-root {

    // }
    .MuiInput-input.MuiInputBase-inputAdornedEnd {
      margin-top: 4px !important;
    }
    .MuiButtonBase-root {
      margin-top: -10px !important;
    }
  }
}

.searchFor-Main-Padding {
  .MuiFormLabel-root {
    top: 2px !important;
  }
  .MuiButtonBase-root {
    margin-top: -13px !important;
  }
  .MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedEnd {
    margin-top: -5px !important;
  }
  .Mui-focused {
    top: -9px !important;
    // .MuiButtonBase-root {

    // }
    .MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedEnd {
      margin-top: 9px !important;
    }
    .MuiButtonBase-root {
      margin-top: 1px !important;
    }
  }
}

// .searchFor-Main-Padding {
//   .MuiInputBase-root {
//     margin-top: 13px !important;
//     .MuiButtonBase-root {
//       margin-top: -10px !important;
//     }
//   }
// }

.main-searchBy label:first-child {
  top: -6px !important;
}
.Setup-details-searchBy {
  .main-searchBy {
    flex: 0.6 1;
  }
}
.contact-main-grid div:nth-child(1) {
  padding-left: 4px !important;
}
.contact-main-grid img {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 2.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  -webkit-user-select: none;
  user-select: none;
}
.Addshipmentrouter {
  font-size: 20px !important;
  opacity: 0.8 !important;
  margin-top: -5px !important;
  color: #000000 !important;
}

.Table-column-color {
  tbody tr:nth-child(odd) {
    background-color: #ededed;
  }

  tbody {
    font-size: 14px !important;
    tr {
      td {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        margin: 0 !important;
        font-size: 14px !important;
      }
    }
  }
}
.drawerClose {
  .dashboardSideNav span {
    font-size: 16px !important;
  }
  .sidenavShipment span {
    color: #d40511 !important;
    font-size: 16px !important;
  }

  .addsidenavShipment span {
    font-weight: bold !important;
  }
  .MuiCollapse-wrapper {
    margin-left: -10px !important;
  }
  .MuiCollapse-wrapperInner {
    margin-left: 15% !important;
  }
  .MuiList-root {
    .MuiCollapse-container.MuiCollapse-entered {
      margin-left: unset !important;
      background-color: #0000000d !important;
      width: auto;
    }
  }
}

// .drawerClose {
//   margin-left: -30px !important;
// }
.MuiBreadcrumbs-li {
  margin-top: -3px !important;
}
.shipmentrouter {
  margin-top: -3px !important;
  opacity: 0.8 !important;
  text-decoration: none !important;
  font-size: 12px !important;
  color: #000000e6 !important;
}
.dialogTitle {
  font-weight: bold !important;
  padding-top: 3% !important;
}
.dialogPopData {
  font-size: 16px !important;
  color: #000000e6 !important;
  letter-spacing: 0 !important;
}
.labelTextfield {
  color: #707070 !important;
  font-size: 16px !important;
}
.labelSelect {
  color: #707070 !important;
  font-size: 16px !important;
}
.Customernumber {
  color: #707070 !important;
}

.MTablePaginationInner-root-24 {
  margin-left: 800px !important;
}

.My-Address-Book-Tab {
  table {
    thead,
    tbody {
      // border: 1px solid #b5b5b5;
      opacity: 1;
    }
  }
}
.MuiMenuItem-root {
  font-size: 14px !important;
}

.MuiBackdrop-root {
  background-color: #ffcc00f2;
  opacity: 0.95;
}

.AddressBook-AddFav {
  margin-right: -34px !important;
}
.AddressBook-Delete {
  //margin-right: -101px !important;
}
.Create-all-shipments-btn,
.Delete-Orders,
.Add-Account-to-all-users,
.Add-to-Account,
.Clone-user .Delete-AddressBook,
.Add-fav-btn,
.Favourites-Remove {
  background: #ffffff99 0% 0% no-repeat padding-box !important;
  border: 1px solid #d40511 !important;
  border-color: #d40511 !important;
  color: #d40511 !important;
  font-size: 16px !important;
  text-transform: none !important;
}
.Re-print-btn {
  background: #d40511 0% 0% no-repeat padding-box !important;
  border: 2px solid #d40511 !important;
  border-color: #d40511 !important;
  color: white !important;
  font-size: 16px !important;
}
.AddressBook-RemoveFav {
  width: 96px !important;
}
.Favourites-Remove {
  margin-left: 108px !important;
}
.AddressBook-Search-control {
  .Addessbook-Searchbar > div > div {
    margin-top: -22px;
    margin-left: 37px;
  }
}
.is_Drawer_Open {
  .AddressBook-Search-control {
    .Addessbook-Searchbar > div > div {
      margin-top: -22px;
      margin-left: 8px;
    }
  }
}
.Favourites-Search-control {
  .Addessbook-Searchbar > div > div {
    margin-top: -22px;
    margin-left: 0px;
  }
}
.AddressBook-main-container {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 6px #00000012;
  border: 1px solid #f2f2f2;
  opacity: 1;
}
.AddressBook-Child-container {
  width: 640px; 
  height: auto;
  margin: 0 auto;
  padding: 10px;
  position: relative;
}
.MyTemplate-Tab .DynamicMaterialTable-main-div tfoot tr td div div:nth-child(5) {
  margin-left: 540px !important;
}
.is_Drawer_Open {
  .MyTemplate-Tab .DynamicMaterialTable-main-div tfoot tr td div div:nth-child(5) {
    margin-left: 430px !important;
  }
}*/
@mixin customCard {
  .customCard {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 6px #00000012;
    border: 1px solid #f2f2f2;
    border-radius: 0px;
  }
}
