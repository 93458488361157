@import '../../../../src//styles//mixin.scss';
.shipment{
    @include customButtons();
   .parcelDimensionsHeading{
       font-size: 20px ;
       font-weight: bold;
       color: #000000E6;
   } 
   .add-btn{
       margin-left: 111px;
       width: 104px !important;
   }
   .save{
    width: 104px !important;
    
   }
   .submit{
       width: 104px !important;
       margin-left: -10px !important;
   }
   .footerRow{
       background-color: #0000000D;
   }
   .addParcel-popup-buttons{
       margin-right: auto;
       margin-top: 2%;
       margin-bottom: 1%;
   }
}