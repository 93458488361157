@import './../../../../styles/mixin.scss';

@include width-100();
.additioninfo-main-grid{
.Delivery{
    flex-grow: 1 !important;
    padding-top: 3% !important;
  
    
}

.emailRecipient{
    flex-grow: 1 !important;
    padding-top: 0% !important;
}
.mobileRecipient{
    flex-grow: 1 !important;
    padding-top: 0% !important;
}
.deliveryNotification{
    margin-top: 0px;
}
}