.addressbookModalcontainer{
    input[type="file"] {
        display: none;
    }
    .custom-file-upload {
        border: 2px solid #d40511 ;
        display: inline-block;
        padding: 6px 12px;
        font-weight: bold;
        color: #d40511;
        cursor: pointer;
    }
.uploadText{
    height: 38px;
    width: 250px;
    margin-left: 20px;
    font-size: 14px;
    border: 1.5px solid darkgray;
}
}