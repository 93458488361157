@import '../../styles/variables';
.drawerClose {
  .saidNavHeading {
    font: 14px/18px $font-bold;
    text-align: left;
    padding: 10px;
  }
  .activeLink {
    span {
      color: #d40511 !important;
    }
  }
}

.faqContainer {
  width: 100%;
  .MuiFormControlLabel-label {
    width: 100%;
  }
}
.faq-right-panel {
  .MuiGrid-root.accordion-Container {
    .MuiAccordionSummary-content {
      font: 14px/18px $font-regular;
    }
  }
  .MuiContainer-maxWidthLg {
    padding-right: 0;
    width: calc(100% + 10px);
  }
  .MuiCheckbox-colorSecondary {
    border: 1px solid grey;
    font-size: 1.2rem;
    width: 24px;
    height: 24px;
    border-radius: 4px;
    fill: none;
    color: #fff !important;
    margin-right: 18px;
  }
  .Mui-checked.MuiIconButton-colorSecondary {
    color: #626262 !important;
  }
  .btn-container {
    .outlined-btn {
      padding: 5px 23px;
    }
    .contained-btn {
      padding: 5px 28px;
    }
  }
}
.add-category-btn.MuiButton-text {
  padding: 5px 60px;
  margin-top: 5px;
}
