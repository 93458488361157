.sliderForAlert{
    .MuiGrid-root{
        button{
        background-color: red;
    }
  
    h2{
        color:red;
    }
    }

    .MuiSvgIcon-root{
        color:#000;
    }
    .MuiSvgIcon-root:hover{
        color:#000;
    }
    .sliderText{
        text-align:center;
        p{
            color:#D40511;
        }
    }
   
.alert-Description{
    color: #000000;
    text-align:center;
}
.MuiGrid-item{
    padding-left: 20px;
}
}


.transitionAlert{
.MuiAlert-icon{
    display: none !important;
}
.MuiAlert-message{
    width:90%;
    padding:0;
}
.MuiAlert-icon{
    display: none;
}
}