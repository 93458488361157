.App {
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@font-face {
  font-family: 'Delivery_W_Rg';
  src: local('Delivery_W_Rg'), url(./assets/fonts/Delivery_W_Rg.woff) format('woff');
}
@font-face {
  font-family: 'Delivery_W_Bd';
  src: local('Delivery_W_Bd'), url(./assets/fonts/Delivery_W_Bd.woff) format('woff');
}
@font-face {
  font-family: 'Delivery_W_Lt';
  src: local('Delivery_W_Lt'), url(./assets/fonts/Delivery_W_Lt.woff) format('woff');
}
@font-face {
  font-family: 'Delivery_W_It';
  src: local('Delivery_W_It'), url(./assets/fonts/Delivery_W_It.woff) format('woff');
}
.DynamicMaterialTable-main-div {
  .MuiTable-root {
    .MuiTableHead-root,
    .MuiTableBody-root {
      .MuiTableRow-root {
        .MuiTableCell-paddingNone {
          .MuiCheckbox-colorSecondary.Mui-checked {
            .MuiIconButton-label {
              .MuiSvgIcon-root {
                fill: #626262;
              }
            }
          }
          .MuiIconButton-label {
            .MuiSvgIcon-root {
              border: 1px solid gray;
              font-size: 1.2rem;
              width: 24px;
              height: 24px;
              border-radius: 4px;
              fill: none;
            }
          }
        }
        .MuiTableCell-root {
          .MuiCheckbox-root {
            color: #fff;
            transition: none;
          }
        }
      }
    }
    .MuiTableFooter-root {
      .MuiToolbar-root {
        .MuiTablePagination-spacer {
          flex: 0;
          margin-left: 1%;
        }
        .MuiInputBase-root {
          .MuiTypography-root {
            flex: 1 1 100%;
            text-align: right;
          }
        }
        div:last-child {
          flex: 1;
          text-align: right;
          margin-right: -21px;
        }
        .MuiSelect-root {
          flex: 1 1 100%;
          padding-right: 40px;
        }
        .MuiTypography-root {
          padding-left: 15px;
        }
      }
    }
  }
}

.alertPadding {
  padding-top: 60px;
}

// .alertDialogContainer{
//   padding-top: 125px ;
// }

.application-updates.paper-container.MuiPaper-elevation1.MuiPaper-root {
  border: 1px solid #f2f2f2 !important;
  border-radius: none !important;
  box-shadow: 0px 1px 6px #00000012 !important;
} 