@import '../../styles/variables';
.auditEnquiryContainer {
  .auditEnquirySubContainer {
    .auditNav {
      text-transform: none;
      font: 16px/18px $font-regular;
      border-bottom: 4px solid transparent;
      min-width: auto;
      padding: 0 0 5px;
      margin-right: 35px;
      margin-top: 20px;
      margin-bottom: 10px;
      min-height: auto;
      opacity: 1;
      color: #000000e6;
    }
    .Mui-selected {
      border-bottom: 4px solid #d40511;
      font: 16px/18px $font-bold;
    }
  }
  .MuiTabs-indicator {
    border: 2px solid #d40511;
    height: 0;
    width: 20% !important;
    display: none;
  }
  .By-shipment-Tab-panel {
    .MuiTabs-indicator {
      width: 110px !important;
    }
  }
  .By-UserId-Tab-panel {
    .MuiTabs-indicator {
      // left: 200px !important;
      width: 72px !important;
    }
  }
  .By-shipment-child-Tab-panel {
    padding-left: 0px !important;
    .MuiTab-wrapper {
      align-items: flex-start !important;
    }
  }
  .By-UserId-child-Tab-panel {
    padding-left: 0px !important;
    .MuiTab-wrapper {
      align-items: flex-start !important;
    }
  }
  .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
    padding: 4px;
  }
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, 0px) scale(0.75);
  }
  .ByShipmentNumber_Export {
    height: 43px !important;
  }
  .DynamicMaterialTable-main-div {
    .MuiPaper-elevation2 {
      box-shadow: none !important;
    }
  }
  .user-id-container {
    .MuiAutocomplete-inputRoot {
      height: 50px;
    }
  }
}
.top-btn-container {
  // border: 2px solid red;
  .export-container.MuiGrid-item {
    padding-top: 0;
    .MuiFilledInput-root {
      height: 50px;
    }
  }
  .btn-container.MuiGrid-item {
    padding-top: 0;
    button {
      height: 50px !important;
      width: 100%;
    }
  }
}
.shipment-tab {
  .export-container.MuiGrid-item {
    .MuiFilledInput-root {
      height: 50px;
    }
  }
  .btn-container.MuiGrid-item {
    button {
      height: 50px !important;
      width: 100%;
    }
  }
}
.search-shipment-box {
  .MuiFilledInput-root {
    position: relative;
    height: 50px;
    .MuiIconButton-root {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}
