.contact-main-grid {
  .MuiGrid-item {
    flex-wrap: wrap;
    margin: 10px 0;
    
    p {
      width: 100%;
      margin: 0;
      position: relative;
      &:first-child {
        min-height: 25px;
        img {
          top: -9px;
        }
        span {
          padding-left: 40px;
          word-wrap: break-word;
      }
      &:last-child {
        min-height: 20px;
        margin-top: 5px;
        img {
          top: -8px;
        }
        span {
          padding-left: 40px;
        }
      }
      img {
        position: absolute;
      }
    }
  }
}
}