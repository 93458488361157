.displayPhoto {
  //padding-left: 10px;
  padding-bottom: 10px;
  //height: 300px;
  img {
    width: 100%;
    height: 280px;
    display: block;
  }

  // .slick-arrow, .slick-prev{
  //     color:blue !important;
  //     border: none !important;

  // }
  // .slick-slide .slick-cloned{
  //     width: "50px" !important;
  // }
  .slick-slide {
    width: 56px !important;
    margin-right: 5px !important;
    // height: 65px !important;
  }
  // .slick-active{
  //     width: "56px" !important;
  // }
  // .slick-current{
  //     width: "56px" !important;
  // }
  // .slick-slide,.slick-active,.slick-current{
  //     width: "56px" !important;
  // }

  .slick-prev:before,
  .slick-next:before {
    color: black !important;
  }
  .selected-image-active {
    border: solid red;
  }
}
.delivery-slider {
  margin-top: 20px;
  .slick-list {
    //  border: 1px solid red;
    width: 230px;
    margin-left: 15px;
  }
  .slick-slide {
    // width: 83px !important;
  }
  .slick-arrow {
    &::before {
      color: gray;
    }
  }
  .slick-prev {
    left: -5px;
    z-index: 1;
    width: 15px;
  }
  .slick-next {
    right: -9px;
    z-index: 1;
    width: 15px;
  }
  img {
    width: 67px;
    height: 75px;
    border: 1px solid #ccc;
  }
}
.is_Drawer_Open {
  .delivery-slider {
    .slick-list {
      width: 170px;
      margin-left: 14px;
    }
    img {
      width: 50px;
      height: 60px;
    }
    .slick-slide {
      //width: 66px !important;
    }
    .slick-prev {
      left: -5px;
    }
    .slick-next {
      right: -5px;
    }
  }
}
