.Template-DialogContainer {
  width: 400px;
  height: 380px;
  margin-left: 795px;
  margin-top: 132px;
  overflow-y: unset;
  .MuiDialog-container {
    position: relative;

    &::after {
      content: ' ';
      position: absolute;
      right: 65px;
      top: -10px;
      border-top: none;
      border-right: 15px solid transparent;
      border-left: 15px solid transparent;
      border-bottom: 15px solid #fff;
    }
  }
  .favourite-dialog {
    width: 450px;
    height: 380px;
    margin-left: 715px;
    margin-top: 100px;
  }
  .MuiDialog-paperWidthSm {
    min-height: 380px;
    width: 360px;
    margin: 0px;
  }
  // .MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthMd.MuiDialog-paperFullWidth{
  //     width: 400px !important;
  //     height: 380px !important;
  //    // left: 50px !important;
  //     top: 50px !important;
  //     box-shadow: 0px 1px  2px #B743001F !important;
  // }
  //     .dialogTitle{
  //         margin-top: 16px !important;
  //     }
  //         .dialogContent{
  //             margin-top: -15px ;

  //             .MuiTableContainer-root{
  //                 overflow-x: unset !important;
  //                 min-height: 200px !important;
  //                 max-height: 350px !important;
  //                 border: 0px solid #707070 !important;
  //             }
  //         }

  //    }
  //    .print-pop{
  //        .MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthMd.MuiDialog-paperFullWidth{
  //            width: unset !important;
  //            height: unset !important;
  //           // left: 50px !important;
  //            top: unset !important;
  //            box-shadow: 0px 1px  2px #B743001F !important;
  //        }
}
.template-dialog.alertDialogContainer {
  margin-left: 788px;
  margin-top: 195px;
}
.Template-DialogContainer.favourite-dialog {
  margin-left: 925px;
  margin-top: 135px;
}
.favourite-dialog.alertDialogContainer {
  margin-left: 895px;
  margin-top: 195px;
  width: 470px;
}
