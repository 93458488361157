@import '../../../../styles//mixin.scss';

.order-setup {
  .table-container {
    .DynamicMaterialTable-main-div {
      width: 100%;
      .MuiPaper-elevation2 {
        box-shadow: none;
      }
    }
    .DynamicMaterialTable-main-div > div > div {
      border: 1px solid #b5b5b5;
      table {
        tr {
          td,
          th {
            padding: 0 16px;
          }
        }
        tbody {
          tr {
            &:nth-child(odd) {
              background: #ededed;
            }
          }
        }
      }
    }
  }
  @include width-100();
  .accordion-main.MuiGrid-item {
    padding-top: 0;
  }
  .Importtype-hr {
    margin: 25px 0 25px 25px;
  }
  .accordion-main {
    .accordion-Container {
      &:nth-child(3) {
        .custom-accordion {
          .content {
            padding: 30px 16px;
          }
        }
      }
    }
  }
  .main-checkbox {
  }
  .MuiCheckbox-colorSecondary.Mui-checked {
    .MuiIconButton-label {
      .MuiSvgIcon-root {
        fill: #626262;
      }
    }

    .MuiSvgIcon-root {
      border: 1px solid gray;
      font-size: 1.2rem;
      width: 24px;
      height: 24px;
      border-radius: 4px;
      fill: none;
    }
  }
  .MuiTableCell-root {
    .MuiCheckbox-root {
      border: 1px solid grey;
      font-size: 1.2rem;
      width: 24px;
      height: 24px;
      border-radius: 4px;
      fill: none;
      svg {
        fill: none;
      }
    }
  }
}
.shipment-confirmation {
  .MuiFormControlLabel-labelPlacementStart {
    margin-right: 0;
  }
}
.directory-output {
  .MuiFormControl-fullWidth {
    margin-left: 50px;
  }
  .MuiFilledInput-adornedEnd {
    height: 50px;
  }
}
.output-file-height {
  height: 85px;
}
.choose-icon.MuiButtonBase-root {
  padding: 0 5px 0 0;
}
.orderfile-btn-container.MuiGrid-item {
  margin-top: 15px;
  .contained-btn {
    padding: 5px 50px;
  }
}
.action-carry-radio {
  .MuiFormGroup-row {
    .MuiFormControlLabel-root {
      &:first-child {
        width: 118px;
      }
      &:last-child {
        width: 108px;
      }
    }
  }
}