.TrackingStepperDetails {
  .slick-slide,
  .slick-active,
  .slick-current {
    // width: 449px !important;
  }

  .slick-arrow,
  .slick-prev {
    color: blue !important;
    border: none !important;
  }
  // .slick-slide .slick-cloned {
  //   width: '50px' !important;
  // }

  // .slick-active {
  //   width: '56px' !important;
  // }
  // .slick-current {
  //   width: '56px' !important;
  // }
  // .slick-slide,
  // .slick-active,
  // .slick-current {
  //   width: '56px' !important;
  // }

  .slick-prev:before,
  .slick-next:before {
    color: #fff !important;
  }
  .selected-image-active {
    border: solid red;
  }
  .slick-prev {
    left: 8px;
    z-index: 9999;
  }
  .slick-next {
    right: 8px;
  }
}
