@import '../../../styles/mixin.scss';
@import '../../../styles/variables';
.ProcessOrders-Container {
  // .Tab-panel{
  // margin-left: 40px !important;
  // }
  // box-shadow: 0px 1px 6px #00000012;
  // padding: 30px 25px 30px;
  .refr-btn {
    position: relative;
    font: 16px/22px $font-regular;
    color: $label-color;
    padding: 0 25px 0 0;
    svg {
      @include position(absolute, $top: 14px, $right: -25px);
    }
  }
  .MuiAppBar-colorPrimary {
    background-color: white !important;
    z-index: unset !important;
  }
  .MuiPaper-elevation4 {
    box-shadow: unset !important;
  }
  .MuiTab-root.MuiTab-textColorInherit {
    span {
      color: $label-color;
      font: 16px/22px $font-regular;
      text-transform: none;
    }
    border-bottom: 2px solid transparent;
    min-width: auto;
    min-height: auto;
    padding: 0;
    overflow: visible;
    margin-right: 40px;
    padding-bottom: 7px !important;
    opacity: 1;
    .MuiBadge-badge {
      color: #fff;
    }
    &:nth-child(2) {
      padding-right: 12px;
    }
    &:nth-child(4) {
      padding-right: 12px;
    }
  }
  .MuiTab-root.MuiTab-textColorInherit.Mui-selected {
    border-bottom: 4px solid #d40511;
    span {
      font: 16px/22px $font-bold;
    }
    .MuiBadge-badge {
      font-family: $font-regular;
    }
  }
  .MuiTabs-indicator {
    display: none;
  }
  .scanner-container {
    .MuiFormControl-root {
      top: -10px;
    }
  }
  .ProcessOrders-AppBar {
    margin-left: 0;
    margin-top: 20px;
    padding-bottom: 10px;
  }
  .Upload-File {
    color: #000000e6;
    font-size: 16px;
    @include position(absolute, $top: 15px, $right: 7px);
    svg {
      fill: #3e2d2e;
      @include position(absolute, $top: 0, $left: 0);
      cursor: pointer;
      path {
        fill: #3e2d2e;
      }
    }
    span {
      padding-left: 30px;
    }
  }
  .SearchBar {
    float: right;
    color: black;
    margin-top: 10px;
  }
  // .upload-file-contianer{
  //   margin-left: 40px !;
  // }
  .MuiBadge-colorError {
    width: 25px;
    height: 25px !important;
    border-radius: 50%;
  }

  .order-btn-container {
    .Delete-Orders {
      padding: 5px 28px;
    }
    .Create-all-shipments-btn {
      padding: 5px 21px;
    }
    .contained-btn {
      padding: 5px 17px;
    }
  }

  // .Orders-Tab-panel {
  //   .MuiTabs-indicator {
  //     width: 50px !important;
  //   }
  // }
  // .Review-Orders-Tab-panel {
  //   .MuiTabs-indicator {
  //     width: 140px !important;
  //   }
  // }
  // .Collections-Tab-panel {
  //   .MuiTabs-indicator {
  //     width: 80px !important;
  //   }
  // }
  // .Review-Collections-Tab-panel {
  //   .MuiTabs-indicator {
  //     width: 160px !important;
  //   }
  // }
  .MuiPaper-elevation2 {
    box-shadow: none;
  }
}
.Orders-Tab-panel {
  .MuiTabs-fixed {
    .MuiTabs-flexContainer {
      margin-top: 5px;
    }
  }
}
.is_Drawer_Open {
  .ProcessOrders-Container {
    .MuiTab-root.MuiTab-textColorInherit {
      margin-right: 20px;
    }
  }
}
.order-btn-container {
  margin-top: -40px !important;
}
.process-upload-dialog {
  .MuiDialog-paperFullWidth {
    min-width: auto;
    min-height: 320px;
    .MuiDialogTitle-root {
      .MuiTypography-root {
        .MuiButtonBase-root {
          padding-right: 0;
        }
      }
    }
  }
  .dialogContent {
    padding: 8px 34px;
  }
}
