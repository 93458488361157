@import '../../../styles/variables';
.Template-Container {
  // box-shadow: 0px 1px 6px #00000012;
  .MuiAppBar-colorPrimary {
    background-color: white !important;
    z-index: unset !important;
  }
  .Create-New-Template-Tab {
    margin-top: 2px !important;
    // margin-left: 20px;
  }
  .MyTemplate-Tab {
    margin-top: 14px !important;
    // margin-left: 42px;
    .MuiPaper-elevation2 {
      box-shadow: none !important;
    }
  }
  .MuiPaper-elevation4 {
    box-shadow: unset !important;
  }
  .MuiTab-root.MuiTab-textColorInherit {
    border-bottom: 4px solid transparent;
    padding: 0 0 5px !important;
    min-width: auto;
    min-height: auto;
    opacity: 1;
    span {
      color: #000000e6 !important;
      text-transform: none !important;
      font: 16px/18px $font-regular;
    }
  }
  .MuiTab-root.MuiTab-textColorInherit.Mui-selected {
    border-bottom: 4px solid #d40511;
    span {
      font: 16px/18px $font-bold;
    }
  }
  .MuiTabs-indicator {
    // border: 2px solid #d40511;
    // height: 0;
    display: none;
  }
}

.Template-AppBar {
  // margin-left: 23px;
  // margin-top: 32px;
  width: 98% !important;
}

.Template-Delete-btn {
  width: 80px;
  .MuiButton-label {
    font-weight: bold;
  }
  padding: 8px 8px !important;
}

.Create-template-tab-panel {
  .MuiTabs-indicator {
    width: 144px !important;
  }
}
.my-template-tab-panel {
  .MuiTabs-indicator {
    left: 194px !important;
    width: 96px !important;
  }
}
.template-Tab.MuiGrid-item {
  margin-top: 30px;
  .MuiTabs-flexContainer {
    button {
      &:first-child {
      }
      &:last-child {
        margin-left: 53px;
      }
    }
  }
}
#templateManagementForm {
  width: 99%;
}
