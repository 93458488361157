@import './../../../styles/mixin.scss';
.custom-accordion {
  @include accordian-header;
 // @include accordion-Content;
  .heading{
    font-weight: bold;
    font-size: 14px;
  }
  .content{
    padding: 15px 25px 15px;
  }
  border: 1px solid #E3E3E3 !important;
    box-shadow: 0px 1px 6px #00000012 !important;
}
