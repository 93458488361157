@import '../../../styles/variables';
.ddl-sub-heading {
  font: 16px/18px $font-bold;
}
.ddl-cust-no,
.ddl-cust-name {
  word-break: break-word;
}
.ddl-cust-no {
  span {
    display: inline-block;
  }
}
.ddl-cust-name {
  padding-right: 20px !important;
}
