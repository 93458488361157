@import '../../../styles/mixin.scss';

.carriageForward {
  // @include customButtons();
  width: 99%;
  .custom-accordion {
    .content {
      padding: 15px 16px;
    }
  }
  .delivery-Address {
    .MuiInputBase-root.Mui-focused {
      #address-search {
        position: absolute;
        top: 0;
        width: 90%;
      }
    }
  }
  .outlined-btn {
    padding: 5px 18px;
  }
  .contained-btn {
    padding: 5px 20px;
  }
}

.grid-collect-form {
  margin-bottom: 30px !important;
  .MuiGrid-item.collect-form {
    padding-right: 10px;
  }
  .MuiGrid-item.deliver-form {
    padding-left: 10px;
  }
}
.collectFrom {
  padding-top: 12px;
}
.deliver-form,
.collect-form {
  .content {
    min-height: 1070px;
  }
}
.ac-sel-numb {
  .select-container {
    .labelSelect {
      font-size: 12px !important;
    }
  }
}
