@import '../../styles/variables';
.accounts-container {
  #AccountFormId {
    .paper-container {
      box-shadow: 0px 1px 6px #00000012;
      border: 1px solid #f2f2f2;
    }
    .upload-btn-container {
      button {
        width: 47px;
        height: 50px;
        min-width: auto;
        svg {
          position: absolute;
          top: 14px;
          right: 12px;
        }
      }
    }
  }
  .MuiInputLabel-filled {
    transform: translate(12px, 10px) scale(0.75);
  }
  .MuiTabs-root {
    margin-bottom: 20px;
  }
  .sel-accout-container {
    margin-bottom: 20px;
  }
  .btn-container {
    margin-top: 25px;
    .contained-btn {
      padding: 5px 50px;
    }
    .outlined-btn {
      padding: 5px 38px;
    }
  }
  .MuiFilledInput-root.Mui-disabled {
    background: #f0f0f0 !important;
    &:hover {
      background: #f0f0f0 !important;
    }
  }
}
.logo-preview-txt {
  width: 80%;
  margin: auto;
  text-align: center;
}
.logo-upload-container {
  position: relative;
  .upload-btn-container {
    position: absolute;
    right: 0;
  }
}
.logo-upload.MuiGrid-grid-xs-10 {
  .MuiFilledInput-root {
    height: 50px;
  }
}
.is_Drawer_Open {
  .logo-upload.MuiGrid-grid-xs-9 {
    max-width: 75%;
    flex-basis: 75%;
  }
}
.comp-requirement {
  //   border: 2px solid red !important;
  font: 14px/19px $font-bold;
}
.return-config.MuiGrid-container {
  position: relative;
  width: 211px;
  float: right;
  button {
    position: absolute;
    top: 3px;
    left: -14px;
    padding: 0;
  }
  i {
    font: 16px/22px $font-italic;
  }
}
.uploaded-img-section {
  .preview-img {
    position: relative;
    img {
      max-height: 100%;
      max-width: 85%;
      height: auto;
      width: auto;
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      box-sizing: border-box;
      margin: auto;
    }
  }
}
