.createUser-container {
  .createUser-text {
    margin-left: 25%;
  }
  position: relative;
  .update-btn-container {
    position: absolute;
    bottom: -15px;
    right: 103px;
  }
  .create-btn-container {
    position: absolute;
    bottom: -30px;
    right: 103px;
  }
}
