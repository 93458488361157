@import '../../../styles/variables';
.Shipment-Container {
  // box-shadow: 0px 1px 6px #00000012;
  .MuiAppBar-colorPrimary {
    background-color: white !important;
    z-index: unset !important;
    margin-left: inherit !important;
  }
  .My-Address-Book-Tab {
    margin-top: 9px !important;
    // margin-left: 20px;
  }
  .Favourites-Tab {
    margin-top: 7px !important;
    margin-left: 42px;
  }
  .MuiPaper-elevation4 {
    box-shadow: unset !important;
  }
  .common-style-font {
  }
  .MuiTab-root.MuiTab-textColorInherit {
    border-bottom: 4px solid transparent;
    padding: 0;
    min-width: auto;
    min-height: auto;
    opacity: 1;
    span {
      color: #000000e6 !important;
      font: 16px/18px $font-regular;
      text-transform: none;
    }
  }
  .MuiTab-root.MuiTab-textColorInherit.Mui-selected {
    border-bottom: 4px solid #d40511;
    span {
      font: 16px/18px $font-bold;
    }
  }
  .MuiTabs-indicator {
    display: none;
  }
  .Addessbook-Searchbar {
    margin-bottom: 7px;
  }
  .Table-column-color {
    .DynamicMaterialTable-main-div {
      .MuiPaper-elevation2 {
        box-shadow: none;
      }
    }
  }
  .AddressBook-Delete {
    button {
      padding: 5px 22px;
    }
  }
  .Re-print-btn {
    padding: 5px 25px;
  }
  .btn-container {
    .MuiGrid-item {
      padding: 10;
      &:last-child {
        margin-right: -11px;
        // margin-left: 20px;
      }
    }
  }
}

.Shipment-AppBar {
  margin-left: 0px;
  margin-top: 0px;
  // width: 98% !important;
}

.Redirect-Link {
  text-decoration: none;
  color: #000;
  // :hover > & {
  //   cursor: default;
  // }
}
.shipment-management-tab {
  .MuiTabs-root {
    margin-top: 10px;
    .MuiTabs-flexContainer {
      button {
        &:last-child {
          margin-left: 47px;
        }
      }
    }
  }
}
