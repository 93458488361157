@import '../../../../styles/mixin.scss';
@include width-100();
.customDeclaration-container {
  margin-top: -10px !important;
  .bd-custom-decl {
    .MuiGrid-item {
      padding: 24px;
    }
  }
}
