.tracking-track {
  .hi {
    .moadal-grid {
      .makeStyles-modal-43 {
        .MuiBackdrop-root {
          .makeStyles-paper-50 {
            width: 800px !important;
          }
        }
      }
    }
  }
}
#changeDeliveryInstrForm {
  textarea {
    padding: 0 !important;
    height: 80px !important;
    overflow: auto !important;
  }
  table {
    // border: 2px solid red;
    .MuiTableRow-root.Mui-selected {
      background: #ffeea9;
      // &:hover {
      //   background: none;
      // }
      pointer-events: none;
    }
  }
}
