@import './../../../../../../styles/mixin.scss';

@include width-100();
.footerRow {
  background-color: #0000000d !important;
}

.wd-100 {
  .footerRow {
    background-color: #0000000d !important;
  }
  .MuiTableCell-footer {
    font-size: inherit;
    color: black;
  }
  .tableContainer {
    border: 1px solid #b5b5b5;
  }
}
.commodityDetails-popup {
  .MuiDialog-paper {
    min-width: auto;
  }
  .MuiIconButton-root {
    padding-right: 0;
  }
  .dialogContent {
    padding: 8px 34px;
  }
  .outlined-btn,
  .contained-btn {
    margin-top: 10px;
  }
}
