.ForgotPassword {
  margin-left: 70px;
  color: black;
  text-decoration: underline;
}

img.ImageLogin {
  width: 635px;
  height: 555px;
}

.ForgotPasswordText {
  color: black;
}

.LoginButton {
  color: white;
}
@media only screen and (max-width: 320px) {
  img.ImageLogin {
    width: 315px;
    height: 280px;
  }
}

@media only screen and (min-width: 375px) {
  img.ImageLogin {
    width: 350px;
    height: 280px;
  }
  .ForgotPassword {
    margin-left: 100px;
    color: black;
  }
}

@media only screen and (min-width: 425px) {
  img.ImageLogin {
    width: 400px;
    height: 280px;
  }
  .ForgotPassword {
    margin-left: 120px;
    color: black;
  }
}

@media only screen and (min-width: 768px) {
  .ForgotPassword {
    margin-left: 90px;
    color: black;
  }

  .Grid2 {
    margin-right: 200px;
  }
  img.ImageLogin {
    width: 400px;
    height: 485pxpx;
  }

  @media only screen and (min-width: 992px) {
    img.ImageLogin {
      width: 650px;
      height: 485px;
    }

    .ForgotPassword {
      margin-left: 140px;
      color: black;
    }
  }
}
