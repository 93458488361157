@import '../../styles/variables';
.Servicepoint-Tbl {
  //border: 1px solid red;
  .MuiList-padding {
    padding: 0;
    .row {
      border-bottom: 1px solid rgba(112, 112, 112, 0.24);
      &:last-child {
        border-bottom: none;
      }
      &:hover {
        background: #ffeea9;
      }
    }
  }
  border: 1px solid rgba(112, 112, 112, 0.24);
  margin-bottom: 30px;
  .list-items {
    display: flex;
    width: 100%;
    .items {
      width: 33.33%;
      h2 {
        font: 14px/18px $font-bold;
        margin: 0;
      }
      p {
        margin: 0;
      }
    }
  }
}
