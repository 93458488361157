@import '../../styles/variables';
@import '../../styles/mixin.scss';
#userManagement {
  .AddressBook-Container {
    .MuiAppBar-colorPrimary {
      background-color: white !important;
      z-index: unset !important;
    }
    .My-Address-Book-Tab {
      margin-top: 9px !important;
    }
    .Favourites-Tab {
      margin-top: 7px !important;
    }
    .MuiPaper-elevation4 {
      box-shadow: unset !important;
    }
    .MuiTabs-indicator {
      display: none;
    }
    .DynamicMaterialTable-main-div > div > div {
      .MuiTable-root {
        tbody {
          tr {
            // border: 2px solid red;
            // position: relative;
          }
        }
      }
    }
  }
  .AddressBook-AppBar {
    margin-left: 0px;
    margin-top: 0px;
  }
  .Addessbook-Searchbar {
    float: right;
  }
  .My-address-book-Tab-panel {
    .MuiTabs-indicator {
      width: 118px !important;
    }
  }
  .Favourites-Tab-panel {
    .MuiTabs-indicator {
      left: 160px !important;
      width: 37% !important;
    }
  }
  .createUser-container {
    .MuiIconButton-root {
      font: 16px/18px $font-regular;
      color: $label-color;
      margin-left: auto;
      display: flex;
      padding-right: 0;
      margin-right: -3px;
      &:hover {
        background: none;
      }
    }
    svg {
      margin-left: 5px;
      margin-top: 2px;
    }
  }
  .Clone-user {
    padding: 5px 34px;
  }
}
.commodityDetails-popup {
  .createUser-container {
    // width: 100%;
    // margin: 0;
    .form-group {
      padding: 0 20px 20px;
    }
    .text-low-visible {
      //opacity: 0.1;
    }
    .role-text {
      font-size: 16px;
      padding-left: 5px;
    }
  }
}
.is_Drawer_Open {
  #userManagement {
    .DynamicMaterialTable-main-div > div > div {
      .MuiTable-root {
        width: 1200px;
      }
    }
  }
}
.role-tooltip {
  .MuiPopover-paper {
    // border: 2px solid red;
  }
}
.clearButton {
  margin-right: -6px;
}

@media screen and (min-width: 1920px) and (max-width: 2560px) {
  .is_Drawer_Open {
    #userManagement {
      .DynamicMaterialTable-main-div > div > div {
        .MuiTable-root {
          width: 100%;
        }
      }
    }
  }
}
.userPopups {
  .MuiDialog-paper {
    min-width: auto;
  }
}

.retailUserPopups {
  .MuiDialog-paper {
    min-width: 1225px;
    min-height: 600px;
  }

  width: 100% !important;
  height: 575px !important;
}

.cloneUserPopups {
  .MuiDialog-paper {
    min-width: 1225px;
    min-height: 600px;
  }

  width: 100% !important;
  height: 650px !important;
}
