
.bordercontainer{
    margin-left: 25px;
    width: 600px;
    display: flex;
    align-items: center;
    
  }
  
  .border{
    border-bottom: 1px solid #B1B1B1;
    width: 100%;
  }
  
  .content {
    padding: 0 10px 0 10px;
    color: #000000E6;
  }