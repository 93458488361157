@import '../../../styles/variables';
.shipment_box {
  div[class^='makeStyles-customHeader-'] {
    //  border: 2px solid red;
  }
}
.Top-10px.shipment-btn-continer {
  padding-top: 30px;
  width: 99%;
  margin: 0;
  .MuiGrid-item {
    padding: 0;
  }
  .outlined-btn {
    padding: 5px 22px;
    margin: 0 21px 0 5px !important;
  }
  .contained-btn {
    padding: 5px 25px;
    margin-right: 5px;
  }
}
.view-custom-icon {
  .MuiButtonBase-root {
    font-family: $font-bold;
    .MuiButton-label {
      position: relative;
    }
    .MuiButton-startIcon {
      position: absolute;
      top: 9px;
      left: -25px;
    }
  }
}
