.addressBookModalContainer{
    //right: 0px;
    
    left: 0;
    margin-left: 40%;
    width: 60%;
     margin-top: 1.5em; 
    position: fixed;
    // margin: auto;

    .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthMd.MuiDialog-paperFullWidth.MuiPaper-elevation24.MuiPaper-rounded
    {

    }
    // .MuiDialogContent-root.dialogContent{
    //      overflow-y: hidden !important;
    // }
    .MuiDialog-paperWidthMd {
        margin-top: auto;
        max-width: 650px;
    min-height: 550px;
    }
.addNewButton{
    width: 134px;
}
}