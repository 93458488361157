@import '../../../../src/styles/mixin.scss';
.addShipment-container {
  margin-top: -1% !important;
  .returnOptions-accordion {
    height: 60px;
  }
}
.shipment {
  @include customButtons();
  .custom-accordion {
    .content {
      padding: 15px 16px;
    }
  }
  .delivery-Address {
    .MuiInputBase-root.Mui-focused {
      #address-search {
        position: absolute;
        top: 0;
        width: 90%;
      }
    }
  }
}
.star {
  display: flex;
}
.star_mrg {
  margin-right: 4px;
  font-size: 1rem !important;
  height: 16px;
  margin-top: 1px !important;
  margin-left: 12% !important;
}
.mrg_t {
  span {
    margin-top: -2px;
  }
}
.favourites {
  margin-top: 0px !important;
  letter-spacing: 0 !important;
  color: #000000e6 !important;
  font-size: 16px !important;
}
.addShipmentPage {
  margin-top: 10px;
}
.addToFav {
  padding: 20px 0;
  .MuiTypography-root.MuiFormControlLabel-label.Mui-disabled {
    color: #d3d3d3 !important;
  }
}
.MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
  height: 42px;
  .MuiAutocomplete-input {
    &:first-child {
      height: 42px;
      padding: 0;
    }
  }
}
