@import '../../../../styles/variables';
.SchemaMaterialTable {
  // margin-top: 30px;
  margin-bottom: 10px;
  .ArrowButton {
    // margin-left: 46%;
    border: 1px solid red;
    margin-right: 45%;
    padding: 10px;
    border-radius: 5px;
    margin: 5px auto;
  }
  .MuiPaper-elevation2 {
    // height: 290px;
  }
  .MuiIconButton-root.Mui-disabled {
    border: 1px solid rgba(0, 0, 0, 0.26);
  }
  .DynamicMaterialTable-main-div > div > div {
    border-radius: 5px;
    min-height: 350px;
    box-shadow: 0px 1px 6px #00000012;
    border: 1px solid #e3e3e3;
    table {
      thead {
        tr {
          th {
            &:first-child {
              padding-left: 5px !important;
            }
          }
        }
      }
      tbody {
        tr {
          td {
            &:first-child {
              padding-left: 5px !important;
            }
          }
        }
      }
    }
  }
}
.Schema-AppBar {
  margin-top: 10px;
  box-shadow: none !important;

  button:disabled {
    cursor: default !important;
    pointer-events: all !important;
  }
}
.createNewSchema {
  .MuiTabs-indicator {
    background-color: #d40511 !important;
    height: 4px !important;
    // width: 118px !important;
  }

  .MuiTab-root.MuiTab-textColorInherit.Mui-selected span {
    color: #000000e6 !important;
    font-weight: bold !important;
    font-size: 16px !important;
    letter-spacing: 0 !important;
  }
  .MuiAppBar-colorPrimary {
    background-color: white !important;
    z-index: unset !important;
    margin-top: 25px;
  }
  .MuiTab-wrapper {
    text-transform: none !important;
  }
  .MuiButtonBase-root.MuiTab-root.MuiTab-textColorInherit {
    font-size: 16px !important;
    color: #000000e6 !important;
    letter-spacing: 0 !important;
  }
  .MuiButtonBase-root.MuiTab-root.MuiTab-textColorInherit.Mui-selected {
    font-weight: bold !important;
  }
  .custom-accordion {
    .content {
      padding: 15px 16px;
    }
  }
}
.red {
  color: red;
}

.errMessage {
  color: red;
  margin: 0;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-family: Delivery Regular;
  font-weight: 400;
  line-height: 1.66;
}
.OrderFileConfiguration-tabs-container.MuiTabs-root {
  min-height: auto;
}
.schema-detail-row1.MuiGrid-spacing-xs-3 {
  .MuiGrid-root {
    padding: 28px 12px 20px;
  }
}
.import-type {
  padding: 0 12px;
  .MuiFormLabel-root {
    font-size: 14px;
  }
}
.import-type-row {
  // padding-bottom: 30px;
}
.upload-eg-file.MuiGrid-item {
  padding-left: 0 !important;
  margin-left: -80px;

  position: relative;
  .textfield {
    position: absolute;
    top: 0;
    left: 62px;
  }
  .MuiFormLabel-filled {
    top: 8px !important;
  }
  .MuiFormControl-root {
    // width: 62%;
  }
  .MuiInputBase-input {
    visibility: hidden;
  }
  .MuiInputBase-adornedEnd {
    .MuiInputBase-input {
      visibility: unset;
    }
  }
  svg {
    cursor: pointer;
  }
}
.arrow-btn-grid {
  margin-top: 130px;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
.upload-row.MuiGrid-grid-lg-12 {
  padding: 10px 12px 10px 15px;
}
.upload-result {
  padding: 0 12px 20px;
  div {
    width: 100%;
    padding: 5px;
    padding: 5px;
    width: 1150px;
    overflow-x: auto;
  }
  table {
    background: none !important;
    padding: 5px;
    width: 100%;
    margin-top: 20px;
    box-shadow: 0px 1px 6px #00000029;
    border: 1px solid #e3e3e3;
    border-radius: 5px;
    border-collapse: collapse;
    tr {
      border: 1px solid #e3e3e3;
      td {
        white-space: nowrap;
        border-bottom: 1px solid #e3e3e3;
        padding: 8px 15px;
      }
    }
    .isHighlightFirstRow {
      &:first-child {
        td {
          border-top: 1px solid #e3e3e3;
          font: 16px/18px $font-bold;
        }
      }
    }
  }
}
.create-schema-btnContainer {
  .outlined-btn {
    padding: 5px 33px;
  }
  .contained-btn {
    padding: 5px 22px;
  }
}
.MySchema-Tab {
  margin-top: -10px;
  .DynamicMaterialTable-main-div > div > div {
    border: 1px solid #b5b5b5;
    table {
      tbody {
        tr {
          &:nth-child(odd) {
            background: #ededed;
          }
        }
      }
    }
    .download-btn {
      .MuiIconButton-root {
        padding: 0;
      }
    }
  }
  .MuiPaper-elevation2 {
    box-shadow: none;
  }
  .outlined-btn {
    padding: 5px 23px;
    margin-top: -30px;
  }
}
.SearchSchemaIcon.MuiIconButton-root {
  padding: 0;
}
.SchemaNameSearch {
  height: 50px;
  position: relative;
  .MuiFormControl-fullWidth {
    //height: 50px !important;
    position: absolute;
    top: -5px;
    right: 0;
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1) !important;
  }
}
.schema-rightTable {
  table {
    tr {
      th {
        &:nth-child(2) {
          span {
            div {
              width: 110px;
              word-break: break-all;
              display: block;
              white-space: normal;
              line-height: 18px;
            }
          }
        }
        &:nth-child(3) {
          span {
            div {
              width: 110px;
              word-break: break-all;
              display: block;
              white-space: normal;
              line-height: 18px;
            }
          }
        }
      }
    }
  }
}
.is_Drawer_Open {
  .upload-eg-file.MuiGrid-item {
    // margin-left: -40px;
    margin-left: -60px;
    .textfield {
      width: 45%;
    }
  }
  .upload-result {
    div {
      width: 940px;
    }
  }
}
.isrow-header .MuiFormControl-fullWidth {
  width: auto !important;
  position: relative;
  top: 12px;
}
.fileExtensionContainer .MuiFilledInput-root{
  width: 101% !important;
}
