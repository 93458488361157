@import '../../../../styles/mixin.scss';

.wd-100 {
  margin-top: -10px !important;
  @include width-100();
  .customerDetails {
    width: 112% !important;
    color: #707070 !important;
    font-size: 12px !important;
  }
  @media only screen and (max-width: 700px) {
    .customerDetails {
      width: 100% !important;
      color: #707070 !important;
      font-size: 12px !important;
    }
  }
  .deliveryAddressDivider {
    margin-top: 8px;
    margin-left: -5px;
    margin-bottom: 8px;
  }
  .deliveryAddressrightgrid {
    margin-left: 5px;
  }
}

.textAreaGrid {
  border: 1px solid #acacac;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  height: 104px;
  opacity: 1;
  border-radius: 5px;
  padding: 10px;
}

.is_Business_textAreaGrid {
  height: 164px;
}
.delivery-addr {
  .MuiFormLabel-root {
    font-size: 14px;
    color: #000000e6;
  }
}
