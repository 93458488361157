.main-checkbox{
    .MuiCheckbox-root{
        color: #fff;
        transition: none;
      }
        .MuiCheckbox-colorSecondary.Mui-checked{
            color: #fff;
            background-color: #000000;
            width: 5%;
            height: 3px;
            padding: 5px;
            margin: 13px;
            left: 0px;
            right: 7px;
        }
        .MuiCheckbox-colorSecondary.Mui-checked:hover {
            background-color: #000;
        }
        .MuiCheckbox-colorSecondary.Mui-disabled {
            color: #fff;
            width: 5%;
            height: 3px;
            padding: 5px;
            margin: 13px;
            left: 0px;
            right: 7px;
           
            .MuiIconButton-label{
                .MuiSvgIcon-root{
                  border: 2px solid rgba(0, 0, 0, 0.26);
                  border-radius: 2px;
                  font-size: 1.2rem;
                  }
              }
        }
    .MuiIconButton-label{
        .MuiSvgIcon-root{
          border: 2px solid gray;
          border-radius: 2px;
          font-size: 1.2rem;
          }
      }
}