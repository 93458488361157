#barcodeConfigurationForm {
  .custom-accordion {
    .content {
      padding: 15px 16px;
    }
  }
  .btn-container {
    margin-top: 145px;
  }
}
