.searchContainer{
    margin: 10px !important;
    top: 117px;
    left: 732px;
    width: 578px;
    height: 148px;
    background: #FFE26F 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000017;
    border: 1px solid #E5C235;
    opacity: 1;
    margin-top: 14px !important;
}
.searchText{
    padding-top: 20px;
    padding-left: 20px;
    font: normal normal bold 18px/24px Delivery;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}
.searchControl{
    padding-left: 20px;
    padding-right: 10px;
}

