.TrackingFindShipment{
    width: 578px;
    opacity: 1;
    box-shadow: 3px 3px 6px #00000017;
}
.searchText{
    padding-top: 20px;
    padding-left: 20px;
    font: normal normal bold 18px/24px Delivery;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}
.searchControl{
    padding-left: 0px;
    padding-right: 0px;
}