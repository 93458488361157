@import '../../../../../src/styles/mixin.scss';

.shipment {
  @include customButtons();
}

.star {
  display: flex;
}

.star_mrg {
  margin-right: 4px;
  font-size: 1.3rem !important;
}

.mrg_t {
  margin-top: -15px !important;
}
.Temp-btn-container.MuiGrid-spacing-xs-3 {
  margin-top: 15px;
  .MuiGrid-item {
    padding: 0;
    &:last-child {
      margin-right: 10px;
      margin-left: 20px;
    }
  }
  .contained-btn {
    padding: 5px 45px;
  }
}
