.doughnut-container {
  canvas {
    // height: 220px !important;
    // width: 450px !important;
    // margin-left: -15% !important;
  }
  .legend-container {
    // padding-left: 57%;
    // margin-top: -46%;
    // position: inherit;
  }
  .legend-Bar {
    height: 20px;
    border-left: 8px solid;
  }
  .pie-charrt-span {
    padding-right: 5px;
  }
  .pie-StatusCount-span {
    padding-left: 30%;
  }
  .list-chart {
    list-style: none;
    width: max-content;
  }

  .doughnut-with-panel {
    .MuiButtonBase-root.MuiTab-root {
      .legend-container {
        padding-left: 70%;
        margin-top: -35%;
        position: relative;
      }
    }
  }
  .labels-legend {
  }
}

.list-items{
  cursor: pointer;
}
