@import '../../styles/variables';
.headerComponent {
  background: transparent linear-gradient(90deg, #ffcc00 0%, #ffcc00 30%, #ffe57f 79%, #fff0b2 100%) 0% 0% no-repeat
    padding-box;

  .shipLogo:hover {
    cursor: pointer;
  }
  .btn-menu {
    text-transform: capitalize;
    padding-right: 0;
  }
  .MuiMenu-list {
    text-decoration: none;
  }
  .MuiListItemIcon-root {
    min-width: 10px;
  }
  .MuiButton-root:hover {
    background-color: unset;
    border: unset;
  }
  .MuiButton-root {
    border: unset;
    color: #000;
  }
  .headerContent {
    color: #000;
    // padding-left: 30px;
    .MuiListItemIcon-root {
      min-width: 34px !important;
    }
    .MuiButton-textSizeSmall {
      text-transform: capitalize;
      font-family: unset;
      color: #000;
    }
    .MuiBadge-colorSecondary {
      background: #fff;
      color: #000;
      border: 1px solid #000;
    }
  }
  .hiddenAlert {
    display: none;
  }
}
.menuListHeader {
  .MuiMenu-paper {
    .MuiMenu-list {
      .MuiMenuItem-root {
        border-bottom: 1px solid #b8b8b882;
        a {
          text-decoration: none;
          color: #000000;
        }
      }
      .MuiMenuItem-root:last-child {
        border-bottom: unset;
      }
      .MuiListItemIcon-root {
        min-width: 34px !important;
      }
    }
  }
}
.updateMenu {
  .MuiMenu-paper {
    left: 900px !important;
    width: 396px;
    .MuiMenu-list {
      .MuiMenuItem-root {
        border-bottom: 1px solid #e8e8e8;
        a {
          text-decoration: none;
          color: #000000;
        }
      }
      .MuiMenuItem-root:nth-last-child(2) {
        border: unset;
      }
      .MuiMenuItem-root:last-child {
        float: right;
        border: unset;
        margin-bottom: 20px;
        margin-right: 5px;
        margin-top: 15px;
        opacity: 1 !important;
        color: #000000e6;
        font-size: 16px !important;
      }
      .MuiListItemIcon-root {
        min-width: 0;
      }
    }
  }
  .applicationText {
    padding: 5px;
  }
  .app-title {
    font: 18px/24px $font-bold;
    padding: 10px 20px;
    display: block;
  }
  .appl-date {
    padding-right: 20px;
    font-family: $font-italic;
  }
}
.user-ddl-header {
  .MuiPopover-paper {
    top: 60px !important;
    border-radius: 0;
    .MuiList-root {
      padding: 0;
      li.MuiListItem-root {
        a {
          display: block;
          width: 100%;
          position: relative;
          .MuiListItemIcon-root {
            position: absolute;
            top: 0;
            left: 0;
          }
          .MuiTypography-root {
            padding-left: 30px;
          }
        }
        &:nth-child(5) {
          a {
            .MuiListItemIcon-root {
              top: 1px;
            }
          }
        }
      }
    }
  }
}
.user-ddl-header.alertDialogContainer {
  .MuiPopover-paper {
    top: 120px !important;
  }
}

.oneTrust-cookie-button {
  border: none !important;
  color: inherit !important;
  background: none !important;
  font-weight: 400 !important;
  font-size: inherit !important;
  padding: inherit !important;
}