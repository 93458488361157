// canvas{

//     width:660px !important;
//     height:253px !important;

//   }
.barMain_Head {
  width: 100%;
  .chartjs-render-monitor {
    width: 100%;
  }
}
