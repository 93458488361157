  .what3words-autosuggest-suggestions {
  }
  
  .what3words-autosuggest-item {
    background: #ffffff !important;
    padding: 0px !important;
    padding-left: 10px !important;
    box-shadow: none !important;
  }
  .what3words-autosuggest-item .match {
    background: #ffffff !important;
    box-shadow: none !important;
  }
  .what3words-autosuggest-item:hover {
    background: #f0f0f0 !important;
    box-shadow: none !important;
  }
  .what3words-autosuggest-item .active,
  .what3words-autosuggest-item:active {
    background: #f0f0f0 !important;
    box-shadow: none !important;
  }
  .what3words-autosuggest-address {
    height: auto !important;
    font-weight: inherit !important;
    font-size: inherit !important;
    line-height: inherit !important;
    color: inherit !important;
  }
  .what3words-autosuggest-address > .what3words-autosuggest-words {
    width: auto !important;
    white-space: inherit !important;
    overflow: inherit !important;
    text-overflow: inherit !important;
    font-size: 18px;
  }
  .what3words-autosuggest-nearest-place {
    padding: 0px 0px 0px 18px !important;
    // font-size: 16px;
  }
  .what3words-autosuggest-nearest-place-text {
    font-size: 18px;
  }
  .what3words-autosuggest-nearest-place-text > div:first-child {
    font-size: inherit !important;
    line-height: inherit !important;
    color: inherit !important;
    text-overflow: inherit !important;
  }
  .what3words-autosuggest-nearest-place-distance {
    font-weight: inherit !important;
    font-size: inherit !important;
    line-height: inherit !important;
  }
  .what3words-autosuggest-error-wrapper {
    z-index: inherit !important;
  }
  .what3words-autosuggest-error {
    top: 0px !important;
    border: 0px !important;
    background: #ffffff !important;
    color: #FF0000;
    font-size: 12px;
  }
  .what3words-autosuggest-message {
    padding: 0px !important;
    color: inherit !important;
    font-size: inherit !important;
    line-height: inherit !important;
  }

  what3words-autosuggest > .what3words-autosuggest.default > .what3words-autosuggest-input-wrapper > input { 
    width: 100%;
    height: 2.8em;
    font-size: 16px;
    font-family: Delivery Regular;
    font-weight: 400;
    border-color: #ffffff;
    border-radius: 4px;
    opacity: 1;
    border: 1px solid #ACACAC;
    background-color: #ffffff;
    color: #000000;
    box-shadow: 0px 0px 0px 0px;
    margin-top: 2px;
    outline: none;
    background: url(../../../../assets/images/what3words.png);
    background-size: 2.8em 2.8em;
    background-repeat: no-repeat;
    background-position: right;
    background-position-y: center;
    padding: 10px 80px 10px 10px;
  }

  what3words-autosuggest > .what3words-autosuggest.default > .what3words-autosuggest-input-wrapper > input:focus { 
    border: 1px solid #ACACAC;
  }

  what3words-autosuggest >.what3words-autosuggest > .what3words-autosuggest-input-wrapper > .what3words-autosuggest-state {
    position: absolute;
    right: 50px;
    z-index: 100;
    width: 20px;
    height: 20px;
    content: " ";
  }

  :not([dir=rtl]):not([dir=ltr]) what3words-autosuggest >.what3words-autosuggest >.what3words-autosuggest-input-wrapper >.what3words-autosuggest-state, [dir=ltr] what3words-autosuggest >.what3words-autosuggest >.what3words-autosuggest-input-wrapper >.what3words-autosuggest-state {
    right: 58px;
  }

// .loading {
//   right: 54px;
// }