.dashboard-details-container {
  width: 100% !important;
  margin: 0 !important;
  .dashboard-details-subcontainer {
    .MuiGrid-root {
      p {
        margin: 0;

        color: #000000e6;
        &:first-child {
          padding-top: 15px;
          font-size: 12px;
          opacity: 0.6;
        }
        &:last-child {
          font-size: 16px;
        }
      }
      &:last-child {
        .MuiGrid-container {
          width: 100%;
          margin-left: auto;
          .MuiGrid-item {
            padding-bottom: 15px !important;
          }
        }
        .outlined-btn {
          padding: 5px 23px;
          width: 100%;
        }
      }
    }
  }
  form {
    width: 100%;
    .MuiButtonBase-root,
    .MuiInputBase-root {
      height: 44px;
    }
  }
  .DynamicMaterialTable-main-div {
    .MuiPaper-elevation2 {
      box-shadow: none;
    }
  }
  .btnMainContainer {
    height: 60px;
  }
  .FilterIcon {
    padding: 2px 0 0 4px;
  }
  .w-full {
    width: 100%;
    padding: 8px 5px !important;
  }
  .btnContainer {
    margin: -12px -20px;
    .MuiGrid-item {
      padding: 0 !important;
      &:first-child {
        margin-right: 19px;
        button {
          padding: 5px 47px;
        }
      }
      &:last-child {
        button {
          padding: 5px 19px;
        }
      }
    }
  }
}
.is_Drawer_Open {
  .dashboard-details-container {
    width: 99% !important;
    .dashboard-details-subcontainer {
      &:last-child {
        .MuiGrid-container {
          width: 100%;
          // margin-left: -25px;
        }
      }
    }
    .DynamicMaterialTable-main-div {
      .MuiTable-root {
        .MuiTableFooter-root {
          .MuiToolbar-root {
            div {
              &:last-child {
                // margin-right: -30px;
              }
            }
          }
        }
      }
      .MuiTablePagination-toolbar {
        margin-left: -10px;
      }
    }
    .btnContainer {
      width: calc(100% + 16px);
      margin: -12px;
    }
  }
}
