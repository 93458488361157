@import '../../../styles/variables';
@import '../../../styles/mixin.scss';
.Dashboard-monitor-list {
  .MuiTableRow-head {
    th {
      padding: 0;
      font: 16px/22px $font-bold;
    }
  }
  .MuiTableBody-root {
    tr {
      td {
        padding: 0 5px 0 0;
      }
    }
  }
  .FilterIcon {
    padding: 5px 0 0 5px;
  }
  .MuiCheckbox-colorSecondary {
    svg {
      @extend .Checkbox-outer;
    }
  }
  .MuiCheckbox-colorSecondary.Mui-checked {
    svg {
      fill: #626262;
    }
  }
}
.Monitor-Btn {
  padding: 23px 10px 0 0;
  float: right;
  .outlined-btn {
    padding: 5px 48px;
    &:first-child {
      margin-right: 21px;
    }
    &:last-child {
      margin-right: 7px;
    }
  }
}
.MuiTableSortLabel-icon {
  font-size: 20px !important;
}
