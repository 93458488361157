.DialogContainer{
 .MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthMd.MuiDialog-paperFullWidth{
     width: 746px !important;
     height: 402px !important;
    // left: 50px !important;
     top: 50px !important;
     box-shadow: 0px 1px  2px #B743001F !important;
 }
 .dialogTitle{
     margin-top: 16px !important;
 }
     .dialogContent{
         margin-top: -15px ;
        
         .MuiTableContainer-root{
             overflow-x: unset !important;
             min-height: 200px !important;
            //  max-height: 350px !important;
             border: 0px solid #707070 !important;
         }
     }
 
}
.print-pop{
    .MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthMd.MuiDialog-paperFullWidth{
        width: unset !important;
        height: unset !important;
       // left: 50px !important;
        top: unset !important;
        box-shadow: 0px 1px  2px #B743001F !important;
    }
}