// @import '../../../../styles/mixin.scss';
// @import '../../../../src/styles/mixin.scss';
@import '../../../../styles/mixin.scss';

// @include width-100();
.setup-container {
  margin-top: -10px !important;
  .MuiFormLabel-root {
    font-size: 14px !important;
    color: #000000e6;
  }
  .searchBy-destinationCountry-grid {
    margin-top: -25px !important;
  }
}
.dispatch-date-align {
  padding-top: 0 !important;
  padding-right: 5px !important;
  @include position(relative, $top: -7px);
  .date-container {
    // width: 185px;
    // margin-left: auto;
  }
}
.booked-msg {
  color: green;
}
.not-booked-msg {
  color: red;
}
.no-message {
  padding-top: 20px;
  display: block;
}
