.stepper-container {
  .stepper-root {
    background-color: unset !important;
  }
  .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    margin-top: 5px !important;
    line-height: 17px;
  }
  .MuiSvgIcon-root.MuiStepIcon-root {
    width: 32px !important;
    height: 32px !important;
    color: white;
    text {
      fill: #00000073 !important;
      width: 24px !important;
      height: 24px !important;
      font-size: 14px !important;
    }
  }
  .MuiSvgIcon-root.MuiStepIcon-root.MuiStepIcon-active {
    width: 32px !important;
    height: 32px !important;
    border-radius: 50% !important;
    border: 3px solid #000000 !important;
    text {
      fill: #000000e6 !important;
      font-weight: bold !important;
    }
  }
  .MuiSvgIcon-root.MuiStepIcon-root.MuiStepIcon-completed {
    width: 32px !important;
    height: 32px !important;
    color: #76bd22 !important;
  }
  .MuiStepConnector-root.MuiStepConnector-horizontal.MuiStepConnector-alternativeLabel.Mui-disabled {
    .MuiStepConnector-line.MuiStepConnector-lineHorizontal {
      border-top-width: 4px !important;
      border-color: #000000 !important;
      opacity: 0.1 !important;
    }
  }
  .MuiStepConnector-root.MuiStepConnector-horizontal.MuiStepConnector-alternativeLabel.MuiStepConnector-active {
    .MuiStepConnector-line.MuiStepConnector-lineHorizontal {
      border-color: #76bd22 !important;
      border-top-width: 4px !important;
    }
  }
  .MuiStepConnector-root.MuiStepConnector-horizontal.MuiStepConnector-alternativeLabel.MuiStepConnector-completed {
    .MuiStepConnector-line.MuiStepConnector-lineHorizontal {
      border-color: #76bd22 !important;
      border-top-width: 4px !important;
    }
  }
}
