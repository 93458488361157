@import '../../../../styles/variables';
.trackingHandler-container {
  margin-top: 27px;
  height: 111px;
  background: transparent linear-gradient(90deg, #ffcc00 0%, #ffcc00 30%, #ffe57f 79%, #fff0b2 100%) 0% 0% no-repeat
    padding-box;
  box-shadow: 0px 3px 6px #00000017;
  opacity: 1;
  padding: 0 0 0 20px;
  .trackingHandler {
    .estimatedOn-grid-item {
      .estimatedOn-text {
        font-size: 18px;
        padding: 9px 0 10px;
        color: $color-black;
        display: block;
        padding-top: 25px;
      }
    }
    .estimation-duration-item {
      color: $color-black;
      .estimation-day {
        font: 26px/24px $font-bold;
        color: #d40612;
        opacity: 1;
      }
      .estimation-day-1 {
        font: 24px/20px $font-bold;
        color: #d40612;
        opacity: 1;
      }
      .estimation-time-from {
        font-size: 18px;
        color: #d40612;
      }
      .estimation-time-and,
      .between {
        font-size: 18px;
      }
    }
  }
}
.stepper-process-itemcontainer {
  .stepper-container {
    .MuiStepper-root {
      padding-right: 2px;
    }
  }
}
.tracking-section {
  width: 100%;
  .tracking-tabs {
    padding: 8px 12px 12px !important;
  }
  .customCard.MuiPaper-elevation1.MuiPaper-root {
    min-height: 470px;
    padding: 5px 20px 0;
    border-radius: 0;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.1) !important;
    .MuiTabs-flexContainer {
      .MuiTab-root {
        text-transform: inherit !important;
        padding: 0;
        min-width: auto;
        font: 16px/18px $font-regular;
        color: #000000e6;
        opacity: 1 !important;
        border-bottom: 2px solid transparent;
        &:last-child {
          margin-left: 20px;
        }
        &:hover {
          background: none;
        }
      }
      .Mui-selected {
        border-bottom: 4px solid #d40511;
        font-family: $font-bold;
      }
    }
    .MuiTabs-indicator {
      display: none;
    }
    .T-Shipment-details {
      margin: 0;
      .MuiGrid-item {
        padding: 0;
        .sub-content {
          padding-bottom: 10px;
        }
      }
      .MuiDivider-root {
        margin: 5px 0 10px;
      }
      .items-section {
        .MuiGrid-container {
          margin: 0;
        }
      }
    }
    .MuiButton-root {
      text-transform: inherit;
      &:hover {
        background: none;
      }
    }
    .VerticalLinearStepper {
      .MuiStepper-root {
        padding: 20px 0 0;
      }
      .MuiStepLabel-label {
        margin-top: 0;
        font-family: $font-bold;
        // overflow: hidden;
        // white-space: nowrap;
        // text-overflow: ellipsis;
      }
      .MuiStepContent-transition {
        .MuiGrid-container {
          .MuiGrid-item {
            padding: 3px 0 0;
            .MuiTypography-root {
              &:last-child {
                padding-bottom: 5px;
              }
            }
            &:last-child {
              img {
                float: right;
                width: 80px;
                margin-right: 10px;
                margin-top: -7px;
              }
            }
          }
        }
      }
      .MuiStep-vertical {
        .MuiStepLabel-vertical {
          .MuiStepLabel-iconContainer {
            // margin-top: -20px;
          }
        }
        &:last-child {
          .MuiStepLabel-iconContainer {
            // margin-top: 0;
          }
        }
      }
    }
    .photos-heading {
      font: 18px/24px $font-regular;
      padding-top: 20px;
    }
    .customer-photos {
      text-align: center;
      color: $color-black;
      padding-top: 100px;
    }
  }
  .shipment-card {
    padding: 0;
    .T-Shipment-details {
      width: 100%;
      .MuiButton-root {
        padding: 0;
        margin-top: -3px;
        line-height: 22px;
      }
      .list-items {
        padding: 0 20px;
        width: 100%;
        .MuiGrid-spacing-xs-1 {
          margin: 0;
        }
      }
    }
    .MuiTabs-root {
      // padding: 0 20px;
    }
  }
}
.tracking-btn-section {
  .MuiGrid-container {
    margin-bottom: 5px;
    .MuiGrid-item {
      padding: 0;
      &:first-child {
        margin-right: 21px;
        button {
          padding: 5px 27px;
        }
      }
      &:last-child {
        margin-right: 12px;
        button {
          padding: 5px 24px;
        }
      }
    }
  }
}
.is_Drawer_Open {
  .tracking-section {
    width: 99%;
    .tracking-tabs {
      width: 100%;
    }
    .customCard {
      min-height: 490px;
    }
  }
  .T-Shipment-details {
    .items-section {
      padding-bottom: 5px !important;
    }
    .MuiDivider-root {
      // margin: 5px 0 10px;
    }
  }
}
.stepper-process-grid {
  .MuiStepConnector-alternativeLabel {
    left: calc(-50% + 15px);
    right: calc(50% + 17px);
    top: 14px;
  }
  .MuiStepLabel-iconContainer {
    div > div {
      display: flex;
      align-items: center;
      font-family: $font-bold;
    }
  }
}
