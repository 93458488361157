.orderFiledefaults-container {
  padding: 20px !important;
  .Mui-selected {
    background: #0000001a;
    border: 1px solid #000000;
    opacity: 1;
    .MuiToggleButton-label {
      text-transform: none !important;
      font-size: 16px !important;
      color: #000;
    }
  }
  .MuiToggleButton-label {
    text-transform: none !important;
    font-size: 16px !important;
  }
  .MuiToggleButtonGroup-groupedHorizontal:not(:first-child) {
  }
}
.OrderFile-default-Tab {
  width: 98%;
  .MuiToggleButtonGroup-root {
    margin-top: -15px;
    .MuiToggleButton-root.MuiToggleButton-sizeSmall {
      border: 1px solid #00000073;
      color: rgba(0, 0, 0, 0.5);
      padding: 7px ​12px;
      font-size: 16px;
    }
    .MuiToggleButton-root.Mui-selected {
      border-color: #000;
      color: #000000;
    }
  }
  .custom-accordion {
    .content {
      padding: 15px 16px;
    }
  }
  .domestic-btn-container,
  .international-btn-container,
  .collection-btn-container {
    padding: 20px 0 0;
    .outlined-btn {
      padding: 5px 32px;
    }
    .contained-btn {
      padding: 5px 18px;
    }
  }
}
