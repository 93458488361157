#genralConfigurationform {
  .custom-accordion {
    .content {
      padding: 15px 20px;
    }
  }
  .accordion-Container {
    &:nth-child(2) {
      .custom-accordion {
        .content {
          padding: 15px 16px;
        }
      }
    }
  }
  .btn-container {
    margin-top: 20px;
    .contained-btn {
      padding: 5px 35px;
    }
  }
}
