.internationalProduct-togglebutton {
  .MuiToggleButtonGroup-groupedHorizontal:not(:last-child) {
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
    border: 2px solid #c1c1c1;
  }

  .MuiToggleButtonGroup-groupedHorizontal:not(:first-child) {
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
    border: 2px solid #c1c1c1;
    // border-left:1.8px groove !important;
  }
  .MuiToggleButtonGroup-groupedHorizontal.Mui-selected {
    border: unset;
    color: white !important;
    path {
      fill: white !important;
    }
  }
  .MuiToggleButtonGroup-groupedHorizontal {
    color: black !important;
    //  path{
    //      fill: none !important;
    //  }
  }
  button {
    &:first-child {
      .MuiToggleButton-label {
        .MuiGrid-root {
          &:first-child {
            position: relative;
            top: 12px;
            left: 10px;
          }
        }
      }
    }
    &:last-child {
      .MuiToggleButton-label {
        .MuiGrid-root {
          &:first-child {
            position: relative;
            top: 8px;
            left: 10px;
          }
        }
      }
    }
  }
}
